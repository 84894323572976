import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      style={{
        backgroundImage: 'url("/images/bg/footer.png")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        color: "#fff",
        paddingTop:"100px",
        paddingBottom:"0px",
        marginTop: "30px"
      }}
    >
        <Container fluid>
          <Row className="text-center">
            <Col className="text-start">
              <Link to="/">
                <img
                  src="/images/logo/main-logo.png"
                  width={250}
                  height={55}
                  alt="Logo"
                  className="mb-4"
                />
              </Link>
            </Col>
            <Col className="text-end">
              <div>
                <a
                  href="https://x.com/onionalph"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-3"
                  style={{ color: "#fff", fontSize: "2rem" }}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://t.me/onionalph"
                  target="_blank"
                  className="me-3"
                  rel="noopener noreferrer"
                  style={{ color: "#fff", fontSize: "2rem" }}
                >
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
    </footer>
  );
}

export default Footer;
