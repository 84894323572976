import { balanceOf, getContractConfig } from "../../services/utils";
import {
    OnionRouter,
    BondingPairTypes,
    OnionRouterInstance,
    TokenLauncherInstance,
    CommentTrackerInstance,
    ProfileTrackerInstance,
    TokenLauncher,
} from "../../artifacts/ts";
import {
    ALPH_TOKEN_ID,
    convertAmountWithDecimals,
    prettifyTokenAmount,
    ONE_ALPH,
    ZERO_ADDRESS,
    web3,
    NULL_CONTRACT_ADDRESS,
} from "@alephium/web3";
import { useWallet } from "@alephium/web3-react";
import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";
import { ApadContext, useAppContext } from "src/context/AppContext";
import { strToHex, hexToStr } from "src/components/utils/Formatters";

interface TradePageSwapProps {
    token: any;
    tokenMeta: any;
    bondingPair: any;
    bondingState: BondingPairTypes.State | undefined;
    tokenId: string;
}

const TradePageSwap: React.FC<TradePageSwapProps> = ({
    token,
    tokenMeta,
    bondingPair,
    bondingState,
    tokenId
}) => {
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");

    const button = document.querySelector('#connectButton');
    const { account, signer } = useWallet();

    const { showPendingTX } = useAppContext();

    const [loading, setLoading] = useState(false);
    const [buyOrSell, setBuyOrSell] = useState("buy");
    const [availableAlph, setAvailableAlph] = useState<bigint>(0n);
    const [availableToken, setAvailableToken] = useState<bigint>(0n);
    const [amount, setAmount] = useState("");
    const [output, setOutput] = useState("");
    const [comment, setComment] = useState("");
    const [graduationReady, setGraduationReady] = useState(false);
    const [referrer, setReferrer] = useState(NULL_CONTRACT_ADDRESS);

    useEffect(() => {
        let tmpRef = localStorage.getItem("referrer");
        if (tmpRef) setReferrer(tmpRef);
        if (bondingState && bondingState.fields) {
            if (bondingState.fields.tokensSold > bondingState.fields.totalTokensForSale - bondingState.fields.totalTokensForSale / 1000n && bondingState.fields.graduated == false) {
                setGraduationReady(true);
            } else {
                setGraduationReady(false);
            }
        } else if (!bondingState && signer) {
            initBondingCurve();
        }
    }, [bondingState])

    useEffect(() => {
        const load = async () => {
            const realAmountIn = convertAmountWithDecimals(amount, 18) ?? 0n;
            if (bondingPair && bondingState && realAmountIn && tokenMeta && token) {
                console.log(tokenMeta);
                try {
                    const estimate = await OnionRouter.at(getContractConfig("OnionRouter").address).view.simulateSwapExactTokenForToken({
                        args: {
                            amountIn: realAmountIn,
                            bondingPair: bondingPair.contractId,
                            slippage: 500n,
                            tokenInId: buyOrSell == "buy" ? ALPH_TOKEN_ID : bondingState.fields.tokenId,
                            dexPair: tokenMeta.dexPair ? tokenMeta.dexPair : getContractConfig("DexPair").tokenId
                        }
                    });
                    setOutput(`${prettifyTokenAmount(estimate.returns[0], 18)} ${buyOrSell == "buy" ? hexToStr(token.symbol) : "ALPH"}`);
                } catch(e) {
                    console.error(e);
                    setOutput("Amount too small or too large.");
                }
            }
        }
        load();
    }, [amount])

    const loadBalances = async () => {
        if (account && account.address && bondingState) {
            const results = await Promise.all([
                balanceOf(ALPH_TOKEN_ID, account.address),
                balanceOf(tokenId, account.address),
            ]);
            setAvailableAlph(results[0]);
            setAvailableToken(results[1]);
        } else {
            setAvailableAlph(0n);
            setAvailableToken(0n);
        }
    }

    useEffect(() => {
        loadBalances()
        const interval = setInterval(loadBalances, 5000);
        return () => clearInterval(interval);
    }, [account]);

    const handleTransaction = async () => {
        if (!account && button) {
            button.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
            return;
        }
        if (!bondingPair || !amount || !tokenMeta || !signer || !bondingState) return;
        const amountReal = convertAmountWithDecimals(amount, 18);
        if (!amountReal) return;

        setLoading(true);

        try {
            const args = {
                bondingPair: bondingPair.contractId,
                amountIn: amountReal,
                amountOutMin: 0n,
                caller: account.address,
                deadline: BigInt(Date.now()) + 120000n,
                tokenInId: buyOrSell == "buy" ? ALPH_TOKEN_ID : bondingState.fields.tokenId,
                tokenLauncher: getContractConfig("TokenLauncher").tokenId,
                comment: strToHex(comment),
                commentTracker: getContractConfig("CommentTracker").tokenId,
                profileTracker: getContractConfig("ProfileTracker").tokenId,
                dexPair: tokenMeta.dexPair ? tokenMeta.dexPair : getContractConfig("DexPair").tokenId,
                referrer: referrer,
            };

            if (buyOrSell == "buy") {
                args["tokenInId"] = ALPH_TOKEN_ID;
                const tx = await OnionRouter.at(getContractConfig("OnionRouter").address).transact.swapExactTokenForToken({
                    args,
                    signer,
                    attoAlphAmount: amountReal + ONE_ALPH,
                });
                showPendingTX(tx.txId);
            } else {
                args["tokenInId"] = bondingState.fields.tokenId;
                const tx = await OnionRouter.at(getContractConfig("OnionRouter").address).transact.swapExactTokenForToken({
                    args,
                    signer,
                    attoAlphAmount: ONE_ALPH,
                    tokens: [{ amount: amountReal, id: bondingState.fields.tokenId }],
                });
                showPendingTX(tx.txId);
            }
        } catch (error) {
            console.error("Transaction failed", error);
        } finally {
            setLoading(false);
        }
    };

    const resetFields = () => {
        setAmount("");
        setOutput("");
        setComment("");
    }

    const initBondingCurve = () => {
        if (signer) {
            TokenLauncher.at(getContractConfig("TokenLauncher").address).transact.createBondingCurve({
                args: { tokenId },
                signer,
                attoAlphAmount: ONE_ALPH,
                tokens: [
                    {
                        id: tokenId,
                        amount: 1_000_000_000n * 10n ** 18n
                    }
                ]
            }).then(x => { showPendingTX(x.txId) });
        }
    }

    if (!bondingState) return (
        <div className="card">
            <div className="card-body">
                <Row className="mb-3">
                    <Button onClick={initBondingCurve}>Initialize Bonding Curve</Button>
                </Row>
            </div>
        </div>
    )

    const graduate = () => {
        if (signer && bondingState) {
            TokenLauncher.at(getContractConfig("TokenLauncher").address).transact.createDexPair({
                signer,
                args: {
                    tokenId: bondingState.fields.tokenId
                }
            }).then(x => { showPendingTX(x.txId) });
        }
    }

    if (graduationReady) return (
        <div className="card">
            <div className="card-body">
                <Row className="mb-3">
                    <Button
                        variant="primary"
                        onClick={() => graduate()}
                    >
                        Graduate To DEX
                    </Button>
                </Row>
            </div>
        </div>
    )


    return (
        <div className="card purple-border">
            <div className="card-body p-0">
                <Row className="mb-3">
                    <Col>
                        <ButtonGroup>
                            <Button
                                variant={buyOrSell == "buy" ? "primary" : "secondary"}
                                onClick={() => setBuyOrSell("buy")}
                            >
                                Buy
                            </Button>
                            <Button
                                variant={buyOrSell == "sell" ? "primary" : "secondary"}
                                onClick={() => setBuyOrSell("sell")}
                            >
                                Sell
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {
                    bondingState.fields.tokensSold == 0n ?
                        <Row className="mb-3 pe-3 ps-3">
                            <Col>Creator must buy first before trading starts!</Col>
                        </Row> : <></>
                }
                <Row className="mb-3 pe-3 ps-3">
                    <Col>
                        <Form.Label>
                            Available:{" "}
                            <span>
                                {prettifyTokenAmount(buyOrSell == "buy" ? availableAlph : availableToken, 18)}{" "}
                                {buyOrSell == "buy" ? "ALPH" : hexToStr(token.symbol)}
                            </span>
                        </Form.Label>
                        <br />
                        <Form.Label>
                            {buyOrSell == "buy" ? <>
                                <Button size="sm" onClick={setAmount.bind(this, "10")} className="pt-1 pb-1 pl-2 pr-2">10</Button>
                                <Button size="sm" onClick={setAmount.bind(this, "30")} className="pt-1 pb-1 pl-2 pr-2 ms-2">30</Button>
                                <Button size="sm" onClick={setAmount.bind(this, "100")} className="pt-1 pb-1 pl-2 pr-2 ms-2">100</Button>
                                <Button size="sm" onClick={setAmount.bind(this, "500")} className="pt-1 pb-1 pl-2 pr-2 ms-2">500</Button>
                            </> :
                                <>
                                    <Button size="sm" onClick={setAmount.bind(this, Math.round(Number(availableToken / 10n ** 18n) * 0.1) + "")} className="pt-1 pb-1 pl-2 pr-2">10%</Button>
                                    <Button size="sm" onClick={setAmount.bind(this, Math.round(Number(availableToken / 10n ** 18n) * 0.25) + "")} className="pt-1 pb-1 pl-2 pr-2 ms-2">25%</Button>
                                    <Button size="sm" onClick={setAmount.bind(this, Math.round(Number(availableToken / 10n ** 18n) * 0.5) + "")} className="pt-1 pb-1 pl-2 pr-2 ms-2">50%</Button>
                                    <Button size="sm" onClick={setAmount.bind(this, Math.round(Number(availableToken / 10n ** 18n) * 1) + "")} className="pt-1 pb-1 pl-2 pr-2 ms-2">100%</Button>
                                </>}
                        </Form.Label>
                        <Form.Group>
                            <Form.Control
                                required
                                type="text"
                                pattern="^\d{1,18}(\.\d{1,18})?$"
                                name="amount"
                                value={amount}
                                placeholder={`Enter amount to ${buyOrSell == "buy" ? "buy" : "sell"}`}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 pe-3 ps-3">
                    <Col>
                        <Form.Label>You are getting:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="0.00"
                            value={output}
                            readOnly
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="mb-3 pe-3 ps-3">
                    <Col>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Add a comment"
                                name="comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 pe-3 ps-3">
                    <Col>
                        <Button
                            variant={buyOrSell == "buy" ? "primary" : "primary"}
                            type="button"
                            style={{ width: "100%" }}
                            onClick={handleTransaction}
                        >
                            {buyOrSell == "buy" ? "Buy" : "Jeet It"}{" "}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TradePageSwap;
