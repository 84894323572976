import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectDetailsShort } from '../../components/utils/SaleTypes';
import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatAddress, formatMcap, formatTimeAgo, hexToStr, parseSocials } from '../../components/utils/Formatters';
import { FungibleTokenMetaData, web3 } from '@alephium/web3';
import { useEffect, useState } from 'react';
import { TokenLauncher } from '../../artifacts/ts';
import { getContractConfig } from '../../services/utils';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useAppContext } from '../../context/AppContext';
import './HomePageSidebar.css';
import ModalMemeCreate from '../../components/modals/launch/ModalMemeCreate';
import { TokenMetaData } from 'src/artifacts/ts/types';
import { TokenModel } from 'src/models/token';



const HomePageSidebar = ({ data }) => {
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);

    const { alphPrice } = useAppContext();

    const [latest, setLatest] = useState<TokenModel[]>([]);
    const [top, setTop] = useState<TokenModel[]>([]);
    const [loading, setLoading] = useState(true);


    const fetchLatest = async () => {
        try {
            let searchUrl = `${ANALYTICS_BASE_URL}/token?pageSize=3&page=0&orderBy=createdTimestamp`;
            const response = await fetch(searchUrl);
            const tokens = await response.json();
            setLatest(tokens.data as TokenModel[]);
        } catch (error) {
            console.error("Error fetching latest data:", error);
        }
    };

    const fetchTop = async () => {
        try {
            let searchUrl = `${ANALYTICS_BASE_URL}/token?pageSize=5&page=0&orderBy=volumeDaily`;
            const response = await fetch(searchUrl);
            const tokens = await response.json();
            setTop(tokens.data as TokenModel[]);
        } catch (error) {
            console.error("Error fetching top data:", error);
        }
    };

    useEffect(() => {
        fetchLatest();
        fetchTop();

        const interval = setInterval(() => {
            fetchLatest();
            fetchTop();
        }, 20000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div>
            {/* Start a New Coin */}
            <Card className="start-coin-card text-center mb-3 pink-border">
                <Card.Img variant="top" src="images/bg/newcoin.png" alt="Start a New Coin" className="start-coin-image" />
                <Card.ImgOverlay>
                    <ModalMemeCreate></ModalMemeCreate>
                </Card.ImgOverlay>
            </Card>

            {/* Top Tokens */}
            <div className=" mb-3 gold-to-red-border">
                <Card className="top-tokens-card">
                    <Card.Header className="top-tokens-header">🏆 TOP TOKENS</Card.Header>
                    <ListGroup variant="flush">
                        {top && top.map((project, index) => (
                            <Link to={"/trade?tokenId=" + project.id}  key={index}>
                                <ListGroup.Item className="top-token-item gold-to-red-border">
                                    <div className="d-flex align-items-center inner">
                                        <img src={`${logoBaseUrl}${project.logo}`} alt="Token" className="token-image me-2" />
                                        <div>
                                            <div className="token-name">{project.name}</div>
                                            <div className="token-symbol">{project.symbol}</div>
                                            <div className="token-mcap">MCap: ${formatMcap(project.marketCap, alphPrice)} Vol: ${formatMcap(project.volumeDaily, alphPrice)}</div>
                                        </div>
                                        <img src={`images/icons/${index + 1}.png`} height={32} width={40} className="ms-auto me-2" />
                                    </div>
                                </ListGroup.Item>
                            </Link>
                        ))}
                    </ListGroup>
                </Card>
            </div>

            {/* Latest Coins Listed */}
            <div className='mb-3 pink-border'>
                <Card className="latest-coins-card">
                    <Card.Header className="latest-coins-header">🔥 LATEST TOKENS LISTED</Card.Header>
                    <ListGroup variant="flush">
                        {latest && latest.map((project, index) => (
                            <Link to={"/trade?tokenId=" + project.id} key={index}>
                                <ListGroup.Item className="latest-coin-item white-border">
                                    <div className="d-flex align-items-center inner">
                                        <img src={`${logoBaseUrl}${project.logo}`} alt="Coin" className="token-image me-2" />
                                        <div>
                                            <div className="coin-name">{project.name}</div>
                                            <div className="coin-category">{project.symbol}</div>
                                        </div>
                                        <img src='images/icons/alph.png' height={32} width={32} className="ms-auto me-2" />
                                    </div>
                                </ListGroup.Item>
                            </Link>
                        ))}
                    </ListGroup>
                </Card>
            </div>
        </div>
    );
};

export default HomePageSidebar;