import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, Spinner, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faDesktop,
    faQrcode,
    faChevronDown,
    faPuzzlePiece
} from '@fortawesome/free-solid-svg-icons';
import { useConnect, useConnectSettingContext } from '@alephium/web3-react';

export default function ModalConnect({ isOpen, onClose }) {
    const [isLoading, setIsLoading] = useState(false);
    const [connectClicked, setConnectClicked] = useState(false);
    const [isWalletInfoOpen, setIsWalletInfoOpen] = useState(false);
    const context = useConnectSettingContext();
    const { connect } = useConnect();

    const onConnect = useCallback(
        (id) => {
            setIsLoading(true);
            context.setConnectorId(id);
            setConnectClicked(true);
        },
        [context]
    );

    useEffect(() => {
        if (connectClicked && isOpen) {
            setConnectClicked(false);
            connect()
                .then(() => {
                    onClose();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [connectClicked, isOpen, connect, onClose]);

    return (
        <Modal show={isOpen} onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-dark modal-fullscreen-sm-down">
            <Modal.Header closeButton>
                <Modal.Title>Connect Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div className="text-center py-4">
                        <Spinner animation="border" variant="primary" />
                        <p className="mt-3">Connecting...</p>
                        <p>Check your wallet for connection request</p>
                    </div>
                ) : (
                    <>
                        <div className="mb-4">
                            <h5>New to Alephium?</h5>
                            <Button
                                variant="secondary"
                                className="w-100"
                                href="https://alephium.org/#wallets"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faDownload} className="me-2" />
                                Get a Wallet
                            </Button>
                        </div>

                        <div className="mb-4">
                            <h5>Connect with</h5>
                            <div className="d-grid gap-2">
                                <Button
                                    variant="primary"
                                    onClick={() => onConnect('injected')}
                                >
                                    <FontAwesomeIcon icon={faPuzzlePiece} className="me-2" />
                                    Browser Extension
                                    <span className="badge bg-info text-dark ms-2">Popular</span>
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => onConnect('desktopWallet')}
                                >
                                    <FontAwesomeIcon icon={faDesktop} className="me-2" />
                                    Desktop Wallet
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => onConnect('walletConnect')}
                                >
                                    <FontAwesomeIcon icon={faQrcode} className="me-2" />
                                    WalletConnect
                                    <span className="badge bg-info text-dark ms-2">Mobile</span>
                                </Button>
                            </div>
                        </div>

                        <div>
                            <Button
                                variant="link"
                                onClick={() => setIsWalletInfoOpen(!isWalletInfoOpen)}
                                aria-expanded={isWalletInfoOpen}
                            >
                                What is a wallet?{' '}
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    style={{
                                        transform: isWalletInfoOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.2s',
                                    }}
                                />
                            </Button>
                            <Collapse in={isWalletInfoOpen}>
                                <div>
                                    <p className="mt-3">
                                        <strong>A Home for your Digital Assets</strong>
                                    </p>
                                    <p>
                                        A wallet is your gateway to Web3. It allows you to send, receive,
                                        and store digital assets like ALPH and NFTs. Think of it as your
                                        blockchain identity and digital wallet combined.
                                    </p>
                                    <div className="d-flex gap-2">
                                        <Button
                                            variant="primary"
                                            href="https://alephium.org/#wallets"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Get a Wallet
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            href="https://docs.alephium.org/wallet"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Learn More
                                        </Button>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
}
