import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import { useWallet } from '@alephium/web3-react';
import { Button, Col, Container, Dropdown, Form, Row, Spinner } from 'react-bootstrap';
import ModalMemeCreate from '../../components/modals/launch/ModalMemeCreate';
import Banner from '../../components/common/Banner';
import './HomePage.css';
import HomePageSidebar from './HomePageSidebar';
import { TokenModel } from 'src/models/token';
import HomePageProjectList from './HomePageProjectList';
import { web3 } from '@alephium/web3';

export default function HomePage() {
  const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`;
  web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL + "");
  const { connectionStatus, nodeProvider, account, signer } = useWallet();

  const [live, setLive] = useState<TokenModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("lastTradeTimestamp");
  const [dexOnly, setDexOnly] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoadMoreEnabled, setIsLoadMoreEnabled] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchData = async (newPage = 0) => {
    setLoadingMore(true);
    let searchUrl = `${ANALYTICS_BASE_URL}/token?bondingPair=true&pageSize=10&page=${newPage}&orderBy=${sortBy}`;
    if (filter) searchUrl += `&search=${filter}`;
    if (dexOnly) searchUrl += `&dexPair=true`;

    try {
      const response = await fetch(searchUrl);
      const tokens = await response.json();
      if (tokens.data.length < 10) {
        setIsLoadMoreEnabled(false);
      }
      if (newPage === 0) {
        setLive(tokens.data);
      } else {
        setLive(prevLive => [...prevLive, ...tokens.data]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleScroll = () => {
    const navbar = document.getElementById("navbar");
    if (window.innerWidth < 768) return;
    if (navbar) {
      if (window.scrollY === 0) {
        navbar.style.setProperty('--bs-bg-opacity', '0');
      } else {
        navbar.style.setProperty('--bs-bg-opacity', '1');
      }
    }
  };

  useEffect(() => {
    const navbar = document.getElementById("navbar");
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (navbar) navbar.style.setProperty('--bs-bg-opacity', '1');
    };
  }, []);

  useEffect(() => {
    fetchData(0);
    setPage(0);
    setIsLoadMoreEnabled(true);
  }, [filter, dexOnly, sortBy]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingMore && page == 0) {
        fetchData(0);
        setPage(0);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [filter, dexOnly, sortBy, page, loadingMore])

  const loadMore = () => {
    fetchData(page + 1);
    setPage(prevPage => prevPage + 1);
  };

  if (loading) return <Loader></Loader>;

  return (
    <>
      <Banner></Banner>
      <Container fluid className="mt-4">
        <Row>
          <Col xl={8} lg={7} md={6} sm={12}>
            <Row className="mb-4">
              <Col sm={12} md={9}>
                <div className="input-group purple-border">
                  <Form.Control
                    type="text"
                    name="filter"
                    value={filter}
                    className="no-border form-control"
                    placeholder="Search Token Symbol"
                    onChange={(e) => setFilter(e.target.value)}
                  />

                  <Dropdown className='sortDropdown'>
                    <Dropdown.Toggle id="dropdown-sort" className="text-white">
                      {sortBy === "createdTimestamp"
                        ? "Newest"
                        : sortBy === "marketCap"
                          ? "Market Cap"
                          : sortBy === "volumeDaily"
                            ? "Top Tokens"
                            : "Recent Trade"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="purple-border">
                      <Dropdown.Item onClick={() => setSortBy("createdTimestamp")}>Newest</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSortBy("marketCap")}>Market Cap</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSortBy("volumeDaily")}>Trending</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSortBy("lastTradeTimestamp")}>Recent Trade</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>

              <Col sm={12} md={3}>
                <div className="input-group-text"
                  style={{ background: "none", border: "none", color: "white", height: "100%" }}
                >
                  <input
                    type="checkbox"
                    checked={dexOnly}
                    onChange={(e) => setDexOnly(e.target.checked)}
                    className="form-check-input"
                  />
                  <label className="ms-2">DEX Only</label>
                </div>
              </Col>
            </Row>

            <HomePageProjectList data={live} />

            {isLoadMoreEnabled && (
              <Row><Col>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    onClick={loadMore}
                    variant='outline-primary'
                    disabled={loadingMore}
                    size="sm"
                  >
                    {loadingMore && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
                    Load More
                  </Button>
                </div>
              </Col></Row>
            )}
          </Col>
          <Col xl={4} lg={5} md={6} sm={12}>
            <HomePageSidebar data={live} />
          </Col>
        </Row>
      </Container>
    </>
  );
}