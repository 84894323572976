import { formatDistance, formatDistanceToNow } from "date-fns";
import xss from "xss";

export function formatTxId(txId: string) {
    if (!txId || txId.length < 5) return <a>Invalid TxId</a>
    return (<a href={`${process.env.REACT_APP_EXPLORER_URL}/transactions/${txId}`} target="_blank">{txId.slice(0, 3)}..{txId.slice(-3)}</a>)
}

export function formatAddress(address: string, name: string | undefined) {
    if (!address || address.length < 5) return <a>Invalid Address</a>
    return (<a className="white-text" href={`${process.env.REACT_APP_EXPLORER_URL}/addresses/${address}`} target="_blank">{name ? name + ".alph" : (address.slice(0, 3) + ".." + address.slice(-3))}</a>)
}

export function formatImageSource(address: string, image: string) {
    if(image) return `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${image}`
    return `/images/profiles/${generateNumberFromString(address)}.png`;
}


// Combines individual social fields into the `socials` field
export function serializeSocials(telegram: string, discord: string, twitter: string) {
    return [
        telegram ? `tg:${telegram}` : "",
        discord ? `ds:${discord}` : "",
        twitter ? `tw:${twitter}` : "",
    ]
        .filter(Boolean)
        .join(";");
};

// Convert string to UTF-8 hex
export function strToHex(str: string) {
    return Array.from(new TextEncoder().encode(str))
        .map(byte => byte.toString(16).padStart(2, "0"))
        .join("");
}

// Convert UTF-8 hex back to string
export function hexToStr(hex: string) {
    if (!hex) return "";
    const match = hex.match(/.{1,2}/g);
    if (!match) return "";
    const bytes = new Uint8Array(match.map(byte => parseInt(byte, 16)));
    return sanitizeForXSS(new TextDecoder().decode(bytes));
}

export function sanitizeForXSS(str: string): string {
	return xss(str);
}

export function hexToUtf16String(hex) {
    let bytes = new Uint16Array(hex.match(/.{1,4}/g).map(byte => parseInt(byte, 16))); // Convert hex to UTF-16 code units
    return String.fromCharCode(...bytes); // Convert to string
}

export function formatRef(account: any) {
    const baseUrl = `${location.protocol}//${location.host}?`;
    if (account) return baseUrl + `referrer=${account.address}`
    return baseUrl;
}


export function formatCAWithRef(tokenId: string, account: any) {
    const baseUrl = `${location.protocol}//${location.host}/trade?tokenId=${tokenId}`;
    if (account) return baseUrl + `&referrer=${account.address}`
    return baseUrl;
}

export function copyText(text: string) {
    navigator.clipboard.writeText(text);
};


function generateNumberFromString(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = (hash * 31 + input.charCodeAt(i)) >>> 0; // Simple hash function
    }
    return (hash % 9) + 1; // Map to range 1-9
}

const suffixMap: { [key: string]: string } = {
    'second': 'sec',
    'seconds': 'secs',
    'minute': 'min',
    'minutes': 'mins',
    'hour': 'hr',
    'hours': 'hrs',
    'day': 'day',
    'days': 'days',
    'week': 'wk',
    'weeks': 'wks',
    'month': 'mo',
    'months': 'mos',
    'year': 'yr',
    'years': 'yrs',
    'about': ''
};
const regex = new RegExp(Object.keys(suffixMap).join('|'), 'gi');

export function formatTimeAgo(timestamp: number) {
    const distance = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    return distance.replace(regex, (matched) => suffixMap[matched.toLowerCase()]);
}


export function formatTimeAgoLong(timestamp: number) {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
}

export function formatMcap(mcapInAlph: number | undefined, alphPrice: number) {
    if (mcapInAlph)
        return Math.round((mcapInAlph * alphPrice * 100)) / 100;
    return 0;
}


export function parseSocials(socials: string) {
    const parts = socials.split(";");
    const socialsObject: Record<string, string> = {};
    
    if (socials.includes("tg:")) {
        socialsObject["telegram"] = socials.match(/tg:([^\s;]+)/)?.[1] || "";
    }
    if (socials.includes("ds:")) {
        socialsObject["discord"] = socials.match(/ds:([^\s;]+)/)?.[1] || "";
    }
    if (socials.includes("tw:")) {
        socialsObject["twitter"] = socials.match(/tw:([^\s;]+)/)?.[1] || "";
    }
    
    return {
        telegram: socialsObject["telegram"] || "",
        discord: socialsObject["discord"] || "",
        twitter: socialsObject["twitter"] || "",
    };
};