import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { prettifyTokenAmount } from '@alephium/web3';
import { getContractConfig } from '../../services/utils';
import { formatAddress, formatImageSource, formatTimeAgo } from '../../components/utils/Formatters';
import './LeaderboardPage.css';

const LeaderboardPage = () => {
    const API_URL = process.env.REACT_APP_ANALYTICS_URL + `/api/profile?pageSize=10`;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [stakers, setStakers] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [isLoadMoreEnabled, setIsLoadMoreEnabled] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const loadStakers = async (newPage = 0) => {
        try {
            if (loading || loadingMore) return;
            if (newPage === 0) setLoading(true);
            else setLoadingMore(true);
            const response = await fetch(`${API_URL}&page=${newPage}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const resp = await response.json();

            if (resp.data.length < 10) {
                setIsLoadMoreEnabled(false);
            }

            if (newPage === 0) {
                setStakers(resp.data);
            } else {
                setStakers((prevStakers) => [...prevStakers, ...resp.data]);
            }
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreStakers = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        loadStakers(nextPage);
    };

    useEffect(() => {
        loadStakers();
    }, []);

    return (
        <Container fluid className="mt-5 mb-5">
            <Row>
                <Col>
                    <img
                        src="images/bg/leaderboard-banner2.png"
                        width={'100%'}
                        className="mt-5 mb-3"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4} className="text-center">
                    <div className='gold-to-red-border mt-3'>
                        {/* <Card>
                            <CardBody> */}
                                <div className="d-flex justify-content-center">
                                    <img
                                        src="images/promo/onion-50knft.gif"
                                        alt="Promotional GIF"
                                        width="97%"
                                        className="mt-2 mb-2 promo-image"
                                        style={{ borderRadius: '12px' }}
                                    />
                                </div>
                                <h4 className="promo-text mt-4 mb-2" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    Earn <strong className="highlight">50k XP</strong> to unlock a special NFT.
                                </h4>
                                <p className="promo-subtext" style={{ fontSize: '0.95rem', fontWeight: 'bold' }}>
                                    NFT holders get <strong className="highlight">lifetime royalties</strong> on platform fees.
                                </p>
                            {/* </CardBody>
                        </Card> */}
                    </div>
                </Col>
                <Col md={8}>
                    <div className="pink-border mt-3">
                        <Card className="mainTable">
                            <CardBody>
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
                                        <Spinner animation="border" />
                                    </div>
                                ) : (
                                    stakers.map((val, index) => (
                                        <div
                                            key={index}
                                            className="d-flex align-items-center py-3 px-3 rounded"
                                        >
                                            <div
                                                className="me-3 d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    backgroundColor: '#3C2757',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                <span className="text-white fw-bold">{index + 1}</span>
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={formatImageSource(val.id, val.pfp)}
                                                        alt="avatar"
                                                        className="rounded-circle me-3"
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <span className="white-text fw-bold">
                                                        {formatAddress(val.id, undefined)}
                                                    </span>
                                                    <a
                                                        href="#"
                                                        className="ms-2 text-decoration-none text-info"
                                                        style={{ fontSize: '0.8rem' }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        ↗
                                                    </a>
                                                    <div
                                                        className="ms-3 mt-1 white-muted-text"
                                                        style={{ fontSize: '0.9rem' }}
                                                    >
                                                        <span className="me-4">
                                                            Joined: {formatTimeAgo(val.createdTimestamp)}
                                                        </span>
                                                        <span>
                                                            Last trade: {formatTimeAgo(val.lastTradeTimestamp)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="text-center"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <div
                                                    className="yellow-text fw-bold"
                                                    style={{ fontSize: '1.2rem' }}
                                                >
                                                    {Math.round(
                                                        Number(BigInt(val.xp) / 10n ** 18n)
                                                    )}
                                                </div>
                                                <div
                                                    className="white-muted-text"
                                                    style={{ fontSize: '0.8rem' }}
                                                >
                                                    XP Points
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {isLoadMoreEnabled && (
                                    <div className="d-flex justify-content-center mt-3 mb-3">
                                        <Button
                                            variant="outline-primary"
                                            onClick={loadMoreStakers}
                                            disabled={loadingMore}
                                        >
                                            {loadingMore && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                            )}
                                            Load More
                                        </Button>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
export default LeaderboardPage;