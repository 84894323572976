import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, Container, Image, Col, Form, Row, Spinner } from 'react-bootstrap';
import { BondingPair, BondingPairInstance, BondingPairTypes, OnionRouter, OnionRouterTypes, OnionRouterInstance, TokenLauncher, TokenLauncherInstance, CommentTracker, ProfileTracker, CommentTrackerInstance, ProfileTrackerInstance, DexPairTypes, DexPair, FeeCollector, FeeCollectorTypes } from "../../artifacts/ts";
import { ALPH_TOKEN_ID, DUST_AMOUNT, FungibleTokenMetaData, Number256, ONE_ALPH, ZERO_ADDRESS, addressFromContractId, convertAmountWithDecimals, prettifyTokenAmount, web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import Loader from "../../components/Loader";
import TradingViewChart from "../../components/partials/TradingViewChart";
import { balanceOf, getContractConfig } from "../../services/utils";
import TradeTable from "../../components/partials/TradeTable";
import CommentTable from "../../components/partials/CommentTable";
import Banner from "../../components/common/Banner";
import TradePageSwap from './TradePageSwap';
import './TradePage.css';
import TradePageStats from './TradePageStats';
import { TokenMetaData } from 'src/artifacts/ts/types';
import { hexToStr } from 'src/components/utils/Formatters';
import { TokenModel } from 'src/models/token';

const TradePage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`;
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const { connectionStatus, nodeProvider, account, signer } = useWallet();

    const onionRouter = OnionRouter.at(getContractConfig("OnionRouter").address);
    const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);
    const commentTracker = CommentTracker.at(getContractConfig("CommentTracker").address);
    const profileTracker = ProfileTracker.at(getContractConfig("ProfileTracker").address);

    const [bondingPair, setBondingPair] = useState<BondingPairInstance>();
    const [bondingState, setBondingState] = useState<BondingPairTypes.State>();
    const [dexPairState, setDexPairState] = useState<DexPairTypes.State>();
    const [feeCollectorState, setFeeCollectorState] = useState<FeeCollectorTypes.State>();
    const [tokenId, setTokenId] = useState(query.get("tokenId") + "");
    const [token, setToken] = useState<FungibleTokenMetaData>();
    const [tokenMeta, setTokenMeta] = useState<TokenMetaData>();
    const [commOrTrade, setCommOrTrade] = useState('comm');
    const [mcap, setMcap] = useState<number>(1000);
    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState<any[]>([]);
    const [comments, setComments] = useState<any[]>([]);
    const [tradePage, setTradePage] = useState(0);
    const [commentsPage, setCommentsPage] = useState(0);
    const [isTradeLoadMoreEnabled, setIsTradeLoadMoreEnabled] = useState(true);
    const [isCommentsLoadMoreEnabled, setIsCommentsLoadMoreEnabled] = useState(true);
    const [autoLoadTrades, setAutoLoadTrades] = useState(true);
    const [autoLoadComments, setAutoLoadComments] = useState(true);
    const [loadingTrades, setLoadingTrades] = useState(true);
    const [loadingComments, setLoadingComments] = useState(true);
    const [apiToken, setApiToken] = useState<TokenModel>();

    const loadTokenData = async () => {
        web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL +"");
        const tokenDetailsResp = await Promise.all([
            tokenLauncher.view.loadTokenMeta({ args: { tokenId } }),
            web3.getCurrentNodeProvider().fetchFungibleTokenMetaData(tokenId)
        ]);

        const tokenMeta = tokenDetailsResp[0];
        setTokenMeta(tokenMeta.returns);
        setToken(tokenDetailsResp[1]);


        let tokenApiUrl = `${ANALYTICS_BASE_URL}/token/${tokenId}`;
        const response = await fetch(tokenApiUrl);
        const tokens = await response.json();
        setApiToken(tokens.data as TokenModel);

        if (tokenMeta.returns.bondingCurve) {
            const bPair = BondingPair.at(addressFromContractId(tokenMeta.returns.bondingCurve));
            setBondingPair(bPair);
            setBondingState(await bPair.fetchState());
        }

        if (tokenMeta.returns.dexPair) {
            const dPair = DexPair.at(addressFromContractId(tokenMeta.returns.dexPair));
            setDexPairState(await dPair.fetchState());
        }

        if (tokenMeta.returns.ownerFeeCollector) {
            const fc = FeeCollector.at(addressFromContractId(tokenMeta.returns.ownerFeeCollector));
            setFeeCollectorState(await fc.fetchState());
        }

        setMcap((await (await fetch(`${ANALYTICS_BASE_URL}/trade/mcap/${getContractConfig("OnionRouter").address}?eventIndex=5&tokenId=${tokenId}`)).json()).data[0]);
        setLoading(false);
    };

    const loadTrades = async (newTradePage = 0) => {
        const resp = await fetch(`${ANALYTICS_BASE_URL}/trade/get/${onionRouter.address}?pageSize=10&page=${newTradePage}&eventIndex=5&tokenId=${tokenId}`);
        const newTrades = (await resp.json()).data;

        if (newTrades.length < 10) {
            setIsTradeLoadMoreEnabled(false);
        }

        if (newTradePage === 0) {
            setTrades(newTrades);
        } else {
            setTrades(prevTrades => [...prevTrades, ...newTrades]);
        }
        setLoadingTrades(false);
    };

    const loadComments = async (newCommentsPage = 0) => {
        const resp = await fetch(`${ANALYTICS_BASE_URL}/comment/get/${commentTracker.address}?pageSize=10&page=${newCommentsPage}&eventIndex=5&topic=${tokenId}`);
        const newComments = (await resp.json()).data;

        if (newComments.length < 10) {
            setIsCommentsLoadMoreEnabled(false);
        }

        if (newCommentsPage === 0) {
            setComments(newComments);
        } else {
            setComments(prevComments => [...prevComments, ...newComments]);
        }
        setLoadingComments(false);
    };

    useEffect(() => {
        loadTokenData();

        const interval = setInterval(() => {
            if (autoLoadTrades) {
                loadTrades(tradePage);
            }
            if (autoLoadComments) {
                loadComments(commentsPage);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [tradePage, commentsPage, autoLoadTrades, autoLoadComments]);

    const loadMoreTrades = async () => {
        if (isTradeLoadMoreEnabled) {
            setLoadingTrades(true);
            const nextTradePage = tradePage + 1;
            setTradePage(nextTradePage);
            await loadTrades(nextTradePage);
            setLoadingTrades(false);
            setAutoLoadTrades(false);
        }
    };

    const loadMoreComments = async () => {
        if (isCommentsLoadMoreEnabled) {
            setLoadingComments(true);
            const nextCommentsPage = commentsPage + 1;
            setCommentsPage(nextCommentsPage);
            await loadComments(nextCommentsPage);
            setLoadingComments(false);
            setAutoLoadComments(false);
        }
    };

    if (loading || !(token && tokenMeta)) return (
        <>
            <Banner></Banner>
            <Container className="mt-4 mb-4">
                <Loader />
            </Container>
        </>
    );

    return (
        <>
            <Container fluid className="mt-5 mb-4 pt-3">
                <Row className="mt-5">
                    <div className="col-lg-8">
                        <Row className="g-3">
                            <Col xs={12} className="d-flex align-items-start me-3 mt-3">
                                <Image
                                    src={`${logoBaseUrl}${hexToStr(tokenMeta.logo)}`}
                                    alt={hexToStr(token.name)}
                                    style={{ maxHeight: "80px", objectFit: "contain" }}
                                    roundedCircle
                                    className='me-2'
                                />
                                <div className="d-flex flex-column justify-content-between">
                                    <h5 className="text-white mb-1 mt-2">
                                        {hexToStr(token.name)} <small>({hexToStr(token.symbol)})</small>
                                    </h5>
                                    <p className="truncate-2-lines mb-0 text-white">
                                        {hexToStr(tokenMeta.description)}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-4 purple-border">
                            <Card>
                                <CardBody className="p-0">
                                    <TradingViewChart tokenId={tokenId} />
                                </CardBody>
                            </Card>
                        </div>
                        <div className="mt-4 purple-border">
                            <Card>
                                <CardBody className="p-0">
                                    <ButtonGroup aria-label="Toggle Comments/Trades">
                                        <Button variant={commOrTrade === "comm" ? "primary" : "secondary"} onClick={() => setCommOrTrade("comm")}>Comments</Button>
                                        <Button variant={commOrTrade === "trade" ? "primary" : "secondary"} onClick={() => setCommOrTrade("trade")}>Trades</Button>
                                    </ButtonGroup>
                                    {commOrTrade === "comm" ?
                                        <>
                                            <CommentTable comments={comments}></CommentTable>
                                            {isCommentsLoadMoreEnabled && (
                                                <div className="d-flex justify-content-center mt-3 mb-3">
                                                    <Button variant="outline-primary" onClick={loadMoreComments} disabled={loadingComments}>
                                                        {loadingComments && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
                                                        Load More Comments
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                        :
                                        <>
                                            <TradeTable trades={trades} token={token}></TradeTable>
                                            {isTradeLoadMoreEnabled && (
                                                <div className="d-flex justify-content-center mt-3 mb-3">
                                                    <Button variant="outline-primary" onClick={loadMoreTrades} disabled={loadingTrades}>
                                                        {loadingTrades && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
                                                        Load More Trades
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <TradePageSwap
                            bondingPair={bondingPair}
                            bondingState={bondingState}
                            token={token}
                            tokenMeta={tokenMeta}
                            tokenId={tokenId}
                        />
                        <div className="mt-4">
                            {bondingState && mcap ?
                                <TradePageStats
                                    bondingPair={bondingPair}
                                    bondingState={bondingState}
                                    dexPairState={dexPairState}
                                    apiToken={apiToken}
                                    feeCollectorState={feeCollectorState}
                                    token={token}
                                    tokenMeta={tokenMeta}
                                    mcap={mcap}
                                /> : <></>}
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default TradePage;