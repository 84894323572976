/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as DexPairContractJson } from "../trade/DexPair.ral.json";
import { getContractByCodeHash } from "./contracts";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";

// Custom types for the contract
export namespace DexPairTypes {
  export type Fields = {
    protocolFeeCollector: HexString;
    royaltyFeeCollector: HexString;
    tokenId: HexString;
    reserve0: bigint;
    reserve1: bigint;
  };

  export type State = ContractState<Fields>;

  export type TradeEvent = ContractEvent<{
    caller: Address;
    alphAmount: bigint;
    tokenAmount: bigint;
    platformFeeAmount: bigint;
    royaltyFeeAmount: bigint;
  }>;

  export interface CallMethodTable {
    getProtocolFeeCollector: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    getRoyaltyFeeCollector: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    getTokenPair: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<[HexString, HexString]>;
    };
    getReserves: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<[bigint, bigint]>;
    };
    validateReserve: {
      params: CallContractParams<{ valReserve0: bigint; valReserve1: bigint }>;
      result: CallContractResult<null>;
    };
    update: {
      params: CallContractParams<{ newReserve0: bigint; newReserve1: bigint }>;
      result: CallContractResult<null>;
    };
    estimateOutput: {
      params: CallContractParams<{ amountIn: bigint; isBuy: boolean }>;
      result: CallContractResult<[bigint, bigint, bigint, bigint, bigint]>;
    };
    swap: {
      params: CallContractParams<{
        caller: Address;
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    getProtocolFeeCollector: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getRoyaltyFeeCollector: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getTokenPair: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getReserves: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    validateReserve: {
      params: SignExecuteContractMethodParams<{
        valReserve0: bigint;
        valReserve1: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    update: {
      params: SignExecuteContractMethodParams<{
        newReserve0: bigint;
        newReserve1: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    estimateOutput: {
      params: SignExecuteContractMethodParams<{
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: SignExecuteScriptTxResult;
    };
    swap: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        amountIn: bigint;
        isBuy: boolean;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<DexPairInstance, DexPairTypes.Fields> {
  encodeFields(fields: DexPairTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = { Trade: 0 };
  consts = {
    MINIMUM_AMOUNT: BigInt("10000"),
    ErrorCodes: {
      ReserveOverflow: BigInt("1"),
      InsufficientLiquidity: BigInt("5"),
      InvalidK: BigInt("6"),
      InsufficientOutputAmount: BigInt("7"),
      InvalidInputAmount: BigInt("8"),
      InputTooLarge: BigInt("9"),
      InsufficientFee: BigInt("10"),
    },
  };

  at(address: string): DexPairInstance {
    return new DexPairInstance(address);
  }

  tests = {
    getProtocolFeeCollector: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(
        this,
        "getProtocolFeeCollector",
        params,
        getContractByCodeHash
      );
    },
    getRoyaltyFeeCollector: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(
        this,
        "getRoyaltyFeeCollector",
        params,
        getContractByCodeHash
      );
    },
    getTokenPair: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<[HexString, HexString]>> => {
      return testMethod(this, "getTokenPair", params, getContractByCodeHash);
    },
    getReserves: async (
      params: Omit<
        TestContractParamsWithoutMaps<DexPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint]>> => {
      return testMethod(this, "getReserves", params, getContractByCodeHash);
    },
    validateReserve: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { valReserve0: bigint; valReserve1: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "validateReserve", params, getContractByCodeHash);
    },
    update: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { newReserve0: bigint; newReserve1: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "update", params, getContractByCodeHash);
    },
    estimateOutput: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { amountIn: bigint; isBuy: boolean }
      >
    ): Promise<
      TestContractResultWithoutMaps<[bigint, bigint, bigint, bigint, bigint]>
    > => {
      return testMethod(this, "estimateOutput", params, getContractByCodeHash);
    },
    swap: async (
      params: TestContractParamsWithoutMaps<
        DexPairTypes.Fields,
        { caller: Address; amountIn: bigint; isBuy: boolean }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "swap", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: DexPairTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const DexPair = new Factory(
  Contract.fromJson(
    DexPairContractJson,
    "",
    "080844157ec1adcd711a727e6b36f638c3b7a35fa19c2734d3d41c9ac2e506ed",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class DexPairInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<DexPairTypes.State> {
    return fetchContractState(DexPair, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeTradeEvent(
    options: EventSubscribeOptions<DexPairTypes.TradeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      DexPair.contract,
      this,
      options,
      "Trade",
      fromCount
    );
  }

  view = {
    getProtocolFeeCollector: async (
      params?: DexPairTypes.CallMethodParams<"getProtocolFeeCollector">
    ): Promise<DexPairTypes.CallMethodResult<"getProtocolFeeCollector">> => {
      return callMethod(
        DexPair,
        this,
        "getProtocolFeeCollector",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getRoyaltyFeeCollector: async (
      params?: DexPairTypes.CallMethodParams<"getRoyaltyFeeCollector">
    ): Promise<DexPairTypes.CallMethodResult<"getRoyaltyFeeCollector">> => {
      return callMethod(
        DexPair,
        this,
        "getRoyaltyFeeCollector",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getTokenPair: async (
      params?: DexPairTypes.CallMethodParams<"getTokenPair">
    ): Promise<DexPairTypes.CallMethodResult<"getTokenPair">> => {
      return callMethod(
        DexPair,
        this,
        "getTokenPair",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getReserves: async (
      params?: DexPairTypes.CallMethodParams<"getReserves">
    ): Promise<DexPairTypes.CallMethodResult<"getReserves">> => {
      return callMethod(
        DexPair,
        this,
        "getReserves",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    validateReserve: async (
      params: DexPairTypes.CallMethodParams<"validateReserve">
    ): Promise<DexPairTypes.CallMethodResult<"validateReserve">> => {
      return callMethod(
        DexPair,
        this,
        "validateReserve",
        params,
        getContractByCodeHash
      );
    },
    update: async (
      params: DexPairTypes.CallMethodParams<"update">
    ): Promise<DexPairTypes.CallMethodResult<"update">> => {
      return callMethod(DexPair, this, "update", params, getContractByCodeHash);
    },
    estimateOutput: async (
      params: DexPairTypes.CallMethodParams<"estimateOutput">
    ): Promise<DexPairTypes.CallMethodResult<"estimateOutput">> => {
      return callMethod(
        DexPair,
        this,
        "estimateOutput",
        params,
        getContractByCodeHash
      );
    },
    swap: async (
      params: DexPairTypes.CallMethodParams<"swap">
    ): Promise<DexPairTypes.CallMethodResult<"swap">> => {
      return callMethod(DexPair, this, "swap", params, getContractByCodeHash);
    },
  };

  transact = {
    getProtocolFeeCollector: async (
      params: DexPairTypes.SignExecuteMethodParams<"getProtocolFeeCollector">
    ): Promise<
      DexPairTypes.SignExecuteMethodResult<"getProtocolFeeCollector">
    > => {
      return signExecuteMethod(
        DexPair,
        this,
        "getProtocolFeeCollector",
        params
      );
    },
    getRoyaltyFeeCollector: async (
      params: DexPairTypes.SignExecuteMethodParams<"getRoyaltyFeeCollector">
    ): Promise<
      DexPairTypes.SignExecuteMethodResult<"getRoyaltyFeeCollector">
    > => {
      return signExecuteMethod(DexPair, this, "getRoyaltyFeeCollector", params);
    },
    getTokenPair: async (
      params: DexPairTypes.SignExecuteMethodParams<"getTokenPair">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getTokenPair">> => {
      return signExecuteMethod(DexPair, this, "getTokenPair", params);
    },
    getReserves: async (
      params: DexPairTypes.SignExecuteMethodParams<"getReserves">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"getReserves">> => {
      return signExecuteMethod(DexPair, this, "getReserves", params);
    },
    validateReserve: async (
      params: DexPairTypes.SignExecuteMethodParams<"validateReserve">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"validateReserve">> => {
      return signExecuteMethod(DexPair, this, "validateReserve", params);
    },
    update: async (
      params: DexPairTypes.SignExecuteMethodParams<"update">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"update">> => {
      return signExecuteMethod(DexPair, this, "update", params);
    },
    estimateOutput: async (
      params: DexPairTypes.SignExecuteMethodParams<"estimateOutput">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"estimateOutput">> => {
      return signExecuteMethod(DexPair, this, "estimateOutput", params);
    },
    swap: async (
      params: DexPairTypes.SignExecuteMethodParams<"swap">
    ): Promise<DexPairTypes.SignExecuteMethodResult<"swap">> => {
      return signExecuteMethod(DexPair, this, "swap", params);
    },
  };

  async multicall<Calls extends DexPairTypes.MultiCallParams>(
    calls: Calls
  ): Promise<DexPairTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends DexPairTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<DexPairTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends DexPairTypes.MultiCallParams | DexPairTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(DexPair, this, callss, getContractByCodeHash);
  }
}
