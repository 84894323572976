import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectDetailsShort } from '../../components/utils/SaleTypes';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { copyText, formatAddress, formatCAWithRef, formatTimeAgo, parseSocials, hexToStr } from '../../components/utils/Formatters';
import { FungibleTokenMetaData, web3 } from '@alephium/web3';
import { useEffect, useState } from 'react';
import { TokenLauncher } from '../../artifacts/ts';
import { getContractConfig } from '../../services/utils';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useAppContext } from '../../context/AppContext';
import { TokenMetaData } from 'src/artifacts/ts/types';
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import { useWallet } from '@alephium/web3-react';
import { TokenModel } from 'src/models/token';
import CopyButtonWithTooltip from 'src/components/common/CopyButtonWithTooltip';


interface HomePageProjectListProps {
    data: TokenModel[],
}

interface ProjectDetail {
    tokenId: string,
    meta: TokenMetaData,
    basic: FungibleTokenMetaData,
    timestamp: number
    mcap: number
}

const HomePageProjectList = ({ data }) => {
    const ANALYTICS_BASE_URL = `${process.env.REACT_APP_ANALYTICS_URL}/api`
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL ? process.env.REACT_APP_NODE_URL : "https://wallet.mainnet.alephium.org");
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);

    const { account } = useWallet()
    const { alphPrice } = useAppContext();

    return (
        <Row>
            {data &&
                data.map((project, index) => (
                    <Col sm={12} md={12} lg={6} key={index}>
                        <div className={project.dexPair ? "gold-border mb-4" : "pink-border mb-4"}>
                            <Card className="main-token-card">
                                <Card.Body>
                                    <Row>
                                        <Col xl={5} md={6} xs={6}>
                                            <Link to={"/trade?tokenId=" + project.id}>
                                                <img
                                                    src={`${logoBaseUrl}${project.logo}`}
                                                    className="img-fluid"
                                                    style={{ maxHeight: "180px" }}
                                                    alt={`${project.name} logo`}
                                                />
                                            </Link>
                                        </Col>
                                        <Col xl={7} md={6} xs={6}>
                                            <img src='images/icons/alph.png' height={32} width={32} className="chain-icon" />
                                            <h5 className="fw-bold white-text">{project.name}</h5>
                                            <h6 className="yellow-text uppercase">{project.symbol}</h6>
                                            <p className="truncate-2-lines white-text" style={{ height: "70px" }}>
                                                {project.description}
                                            </p>
                                            <div className="d-flex align-items-center socials">
                                                {parseSocials(project.socials) && parseSocials(project.socials).twitter &&
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        className="me-2"
                                                        href={parseSocials(project.socials).twitter}
                                                        target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faTwitter} />
                                                    </Button>
                                                }
                                                {parseSocials(project.socials) && parseSocials(project.socials).telegram &&
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        className="me-2"
                                                        href={parseSocials(project.socials).telegram}
                                                        target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faTelegram} />
                                                    </Button>
                                                }
                                                {parseSocials(project.socials) && parseSocials(project.socials).discord &&
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        className="me-2"
                                                        href={parseSocials(project.socials).discord}
                                                        target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faDiscord} />
                                                    </Button>
                                                }

                                                <CopyButtonWithTooltip account={account} tokenId={project.id} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <span className="small white-text">
                                            Owner: {formatAddress(project.owner, undefined)} <span className="white-muted-text">{formatTimeAgo(project.createdTimestamp)}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="yellow-text fw-bold">Mcap: <span className='white-text'>${(project.marketCap * alphPrice).toFixed(2)}</span></span>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                ))}
        </Row>

    );
};

export default HomePageProjectList;