import React from 'react';
import { Table, Image } from 'react-bootstrap';
import { formatAddress, formatImageSource, formatTimeAgo, hexToStr } from '../utils/Formatters';
import { ALPH_TOKEN_ID, prettifyTokenAmount } from '@alephium/web3';

const TradeTable = ({ trades, token }) => {
    return (
        <div className="table-responsive">
            <Table striped hover variant='dark' responsive>
                <thead>
                    <tr>
                        <th>Account</th>
                        <th>Type</th>
                        <th>{hexToStr(token.symbol)}</th>
                        <th>ALPH</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {trades.length > 0 ? (
                        trades.map((trade, index) => (
                            <tr key={trade.eventHash}>
                                <td>
                                    <Image
                                        src={formatImageSource(trade.field2, "")}
                                        roundedCircle
                                        width={40}
                                        height={40}
                                        alt="Profile"
                                        className="me-2"
                                    /> 
                                    {formatAddress(trade.field2, undefined)}
                                </td>
                                <td>{trade.field3 == ALPH_TOKEN_ID ? <span style={{ color: "#0f0" }}>Buy</span> : <span style={{ color: "#f00" }}>Sell</span>}</td>
                                <td>{prettifyTokenAmount(trade.field3 == ALPH_TOKEN_ID ? trade.field5 : trade.field4, 18)}</td>
                                <td>{prettifyTokenAmount(trade.field3 == ALPH_TOKEN_ID ? trade.field4 : trade.field5, 18)}</td>
                                <td>{formatTimeAgo(trade.timestamp)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center">
                                No trades found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default TradeTable;
