import { web3 } from "@alephium/web3";
import React, { useEffect, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { formatTxId } from "../utils/Formatters";

interface PendingTXProps {
    isOpen: boolean; // Controls visibility of the modal
    onClose: () => void; // Function to close the modal
    txID: string; // Transaction hash to display
}

const PendingTX: React.FC<PendingTXProps> = ({
    isOpen,
    onClose,
    txID,
}) => {
    const [txStatus, setTxStatus] = useState<string>("Pending");

    useEffect(() => {
        if (!isOpen) return; // Only run the effect when the modal is open

        const checkTransactionStatus = async () => {
            try {
                const res = await web3.getCurrentNodeProvider().transactions.getTransactionsStatus({ txId: txID });
                setTxStatus(res.type || "Pending");
            } catch (error) {
                console.error("Error fetching transaction status:", error);
            }
        };

        // Run the function immediately and then every 5 seconds
        checkTransactionStatus();
        const interval = setInterval(checkTransactionStatus, 5000);

        return () => clearInterval(interval); // Cleanup interval when modal closes
    }, [isOpen, txID]);

    if (!isOpen) return null;

    return (
        <Modal show={isOpen} onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-dark modal-fullscreen-sm-down"
        >
            <Modal.Header closeButton>
                <Modal.Title>Transaction Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>Transaction ID:</strong> {formatTxId(txID)}
                </p>
                <p>
                    <strong>Status:</strong> {txStatus=="Confirmed"?<>Confirmed</>:<>Pending</>}
                </p>
                <Alert variant="info">
                    <a
                        href={`${process.env.REACT_APP_EXPLORER_URL}/transactions/${txID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                    >
                        View on Explorer
                    </a>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PendingTX;
