import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { formatAddress, formatTimeAgo } from '../utils/Formatters';
import { prettifyTokenAmount } from '@alephium/web3';

const TopHolderTable = ({ tokenId }) => {

    const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}`
    const [topHolders, setTopHolders] = useState<any[]>([]);

    useEffect(() => {
        fetch(`${BACKEND_BASE_URL}/tokens/holders/token/${tokenId}?page=1&limit=100`).then(x => x.json()).then(x => {
            console.log(x);
            setTopHolders(x);
        });
    }, [])

    return (
        <div className="table-responsive">
            <Table striped hover variant='dark' responsive>
                <tbody>
                    {topHolders.length > 0 ? (
                        topHolders.map((holder, index) => (
                            <tr key={index}>
                                <td className="ps-3">{index + 1}. {formatAddress(holder.address, undefined)}</td>
                                <td className="ps-3">{prettifyTokenAmount(holder.balance, 18)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center pl-3">
                                No Holders Found, Holders list updates once a day at 04:00 UTC!
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default TopHolderTable;
