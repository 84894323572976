/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  ExecutableScript,
  ExecuteScriptParams,
  ExecuteScriptResult,
  Script,
  SignerProvider,
  HexString,
} from "@alephium/web3";
import { getContractByCodeHash } from "./contracts";
import { default as SetFeeCollectorScriptJson } from "../onion/SetFeeCollector.ral.json";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";

export const SetFeeCollector = new ExecutableScript<{
  feeHandler: HexString;
  feeCollector: HexString;
}>(
  Script.fromJson(SetFeeCollectorScriptJson, "", AllStructs),
  getContractByCodeHash
);
