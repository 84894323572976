import React, { createContext, useContext, useEffect, useState } from "react";

import { AlephiumConnectProvider, AlephiumWalletProvider, ConnectSettingProvider } from '@alephium/web3-react'
import { NetworkId, web3 } from "@alephium/web3";
import PendingTX from "src/components/partials/PendingTX";
import ModalConnect from "src/components/modals/connect/ModalConnect";

interface AppContextType {
   showPendingTX: (txID: string) => void,
   closePendingTX: () => void,
   alphPrice: number;
}

export const ApadContext = createContext<AppContextType>({
   showPendingTX: () => { },
   closePendingTX: () => { },
   alphPrice: 0
});

export const useAppContext = () => useContext(ApadContext);

const AppProvider = ({ children }) => {
   const [isPendingTXOpen, setIsPendingTXOpen] = useState(false);
   const [pendingTXId, setPendingTXId] = useState<string>("");


   const showPendingTX = (txID: string) => {
      setPendingTXId(txID);
      setIsPendingTXOpen(true);
   }
   const closePendingTX = () => {
      setPendingTXId("");
      setIsPendingTXOpen(false);
   }

   const [alphPrice, setAlphPrice] = useState<number>(0);


   // Fetch market price data periodically
   useEffect(() => {
      const fetchPrice = async () => {
         try {
            const response = await fetch("https://backend.mainnet.alephium.org/market/prices?currency=usd", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify(["ALPH"]),
            });
            const priceData = await response.json();
            setAlphPrice(priceData[0]);
         } catch (error) {
            console.error("Error fetching market price:", error);
         }
      };

      fetchPrice();

      // Update price every minute
      const interval = setInterval(fetchPrice, 60000);

      return () => clearInterval(interval); // Cleanup on unmount
   }, []);

   return (
      <AlephiumConnectProvider
         network={process.env.REACT_APP_NETWORK as NetworkId}
         addressGroup={parseInt(process.env.REACT_APP_ACCOUNT_GROUP ?? "0")}
      >
         <ConnectSettingProvider>
            <ApadContext.Provider value={{ showPendingTX, closePendingTX, alphPrice }}>
               {children}
               <PendingTX isOpen={isPendingTXOpen} onClose={closePendingTX} txID={pendingTXId}></PendingTX>
            </ApadContext.Provider>
         </ConnectSettingProvider>
      </AlephiumConnectProvider>
   );
};

export default AppProvider;