import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, OverlayTrigger, Tooltip, Button, Alert, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ONE_ALPH, waitForTxConfirmation, web3 } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import { getContractConfig } from '../../../services/utils';
import { TokenLauncher } from '../../../artifacts/ts/TokenLauncher';
import GenericImageUpload from '../../common/GenericImageUpload';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PictureHandler from '../../common/pictureHandler/PictureHandler';
import { useAppContext } from 'src/context/AppContext';
import { hexToStr, parseSocials, strToHex } from 'src/components/utils/Formatters';
import { useNavigate } from 'react-router-dom';

interface ModalMemeCreateProps {
    tokenId?: string;
}
const ModalMemeCreate: React.FC<ModalMemeCreateProps> = ({ tokenId }) => {
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,quality=75/";
    const { account, signer } = useWallet();
    const button = document.querySelector('#connectButton');
    web3.setCurrentNodeProvider(process.env.REACT_APP_NODE_URL || "https://wallet.mainnet.alephium.org");

    const navigate = useNavigate();

    const { showPendingTX } = useAppContext();

    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [logo, setLogo] = useState("");
    const [description, setDescription] = useState("");
    const [discord, setDiscord] = useState("");
    const [telegram, setTelegram] = useState("");
    const [twitter, setTwitter] = useState("");

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const showModalClick = () => setShowModal(true);
    const cancelModal = () => setShowModal(false);

    useEffect(() => {
        if (tokenId) {
            setLoading(true);
            const loadTokenData = async () => {
                const tokenDetailsResp = await Promise.all([
                    TokenLauncher.at(getContractConfig("TokenLauncher").address).view.loadTokenMeta({ args: { tokenId } }),
                    web3.getCurrentNodeProvider().fetchFungibleTokenMetaData(tokenId)
                ]);
                setName(hexToStr(tokenDetailsResp[1].name));
                setSymbol(hexToStr(tokenDetailsResp[1].symbol));
                setDescription(hexToStr(tokenDetailsResp[0].returns.description));
                setLogo(hexToStr(tokenDetailsResp[0].returns.logo));
                const socials = parseSocials(hexToStr(tokenDetailsResp[0].returns.socials));
                setTelegram(socials.telegram);
                setDiscord(socials.discord);
                setTwitter(socials.twitter);
                setLoading(false);
            }
            loadTokenData();
        }
    }, [tokenId]);

    const saveModal = async () => {
        if (signer && !loading) {
            const tokenLauncher = TokenLauncher.at(getContractConfig("TokenLauncher").address);
            const socialsCombined = `${twitter ? `tw:${twitter};` : ""}${telegram ? `tg:${telegram};` : ""}${discord ? `ds:${discord};` : ""}`;
            if (name && symbol && description && logo) {
                setError("");
                setLoading(true);
                try {
                    if (tokenId) {
                        const tx = await tokenLauncher.transact.updateTokenMeta({
                            args: {
                                tokenId: tokenId,
                                description: strToHex(description),
                                logo: strToHex(logo),
                                socials: strToHex(socialsCombined)
                            },
                            attoAlphAmount: ONE_ALPH,
                            signer,
                        });
                        showPendingTX(tx.txId);
                        await waitForTxConfirmation(tx.txId, 1, 3000);
                        navigate(`/trade?tokenId=${tokenId}`)
                    } else {
                        const tx = await tokenLauncher.transact.createToken({
                            args: {
                                name: strToHex(name),
                                symbol: strToHex(symbol),
                                description: strToHex(description),
                                logo: strToHex(logo),
                                socials: strToHex(socialsCombined)
                            },
                            attoAlphAmount: ONE_ALPH,
                            signer,
                        });
                        showPendingTX(tx.txId);
                        await waitForTxConfirmation(tx.txId, 1, 3000);
                        let events = await web3.getCurrentNodeProvider().events.getEventsTxIdTxid(tx.txId);
                        let tokenEvent = events.events.filter(x => x.contractAddress == tokenLauncher.address && x.eventIndex == 5)[0];
                        navigate(`/trade?tokenId=${tokenEvent.fields[0].value}`)
                    }
                    setLoading(false);
                    cancelModal();
                } catch (e) {
                    console.error("Failed to save token:", e);
                    setLoading(false);
                }
            } else {
                setError("Logo, name, symbol and description are required!");
                setLoading(false);
            }
        } else if (button) {
            button.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
        }
    };

    const imageUpdate = async (fileName: string, fullUrl: string) => {
        setLogo(fileName);
    };

    return (
        <>
            <Button variant="primary" className="make-coin-button" onClick={showModalClick}>{tokenId ? 'EDIT TOKEN' : 'MAKE TOKEN'}</Button>
            <Modal
                show={showModal}
                onHide={cancelModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="modal-dark modal-fullscreen-sm-down"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{tokenId ? 'Edit Token' : 'Create a Token'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Container>
                            <Row className="mb-3">
                                <Col className="text-center" id="topSection">
                                    <PictureHandler
                                        label="Logo"
                                        minHeight={100}
                                        minWidth={100}
                                        onUploadComplete={imageUpdate}
                                        existing={logo}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            Token Name &nbsp;
                                            <OverlayTrigger
                                                overlay={<Tooltip>Enter the name of the token.</Tooltip>}
                                                placement="top"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Token Name"
                                            value={name}
                                            disabled={tokenId?true:false}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={32}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="symbol">
                                        <Form.Label>
                                            Token Symbol &nbsp;
                                            <OverlayTrigger
                                                overlay={<Tooltip>Enter the symbol of the token.</Tooltip>}
                                                placement="top"
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Token Symbol"
                                            disabled={tokenId?true:false}
                                            value={symbol}
                                            onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                                            maxLength={10}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter token description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            maxLength={180}
                                            rows={3}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="twitter">
                                        <Form.Label>Twitter</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Twitter URL"
                                            value={twitter}
                                            onChange={(e) => setTwitter(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="telegram">
                                        <Form.Label>Telegram</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Telegram URL"
                                            value={telegram}
                                            onChange={(e) => setTelegram(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="discord">
                                        <Form.Label>Discord</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Discord URL"
                                            value={discord}
                                            onChange={(e) => setDiscord(e.target.value)}
                                            maxLength={60}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {error ??
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Alert>{error}</Alert>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={saveModal}>
                        Save
                        {loading && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="ms-3"
                            />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalMemeCreate;