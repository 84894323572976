import React from 'react';
import { Table, Image } from 'react-bootstrap';
import { formatAddress, formatImageSource, formatTimeAgo, hexToStr } from '../utils/Formatters';
import { prettifyTokenAmount } from '@alephium/web3';

const CommentTable = ({ comments }) => {
    return (
        <div className="table-responsive">
            <Table striped hover variant='dark' responsive>
                <thead>
                    <tr>
                        <th>Account</th>
                        <th>Comment</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {comments.length > 0 ? (
                        comments.map((trade, index) => (
                            <tr key={trade.eventHash}>
                                <td>
                                <Image
                                    src={formatImageSource(trade.field2, "")}
                                    roundedCircle
                                    width={40}
                                    height={40}
                                    alt="Profile"
                                    className="me-2"
                                /> 
                                    {formatAddress(trade.field2, undefined)}
                                </td>
                                <td>{hexToStr(trade.field4)}</td>
                                <td>{formatTimeAgo(trade.timestamp)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center">
                                No Comments found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default CommentTable;
