import React from "react";
import { Card, Row, Col, ProgressBar, Button, CardBody, Table, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { BondingPairTypes, DexPairTypes, FeeCollector, FeeCollectorTypes } from "../../artifacts/ts";
import { prettifyTokenAmount } from "@alephium/web3";
import { useAppContext } from "../../context/AppContext";
import TopHolderTable from "src/components/partials/TopHolderTable";
import { formatImageSource, formatAddress, parseSocials, hexToStr, formatMcap } from "src/components/utils/Formatters";
import { useWallet } from "@alephium/web3-react";
import CopyButtonWithTooltip from "src/components/common/CopyButtonWithTooltip";
import { TokenModel } from "src/models/token";
import ModalMemeCreate from "src/components/modals/launch/ModalMemeCreate";

interface TradePageStatsProps {
    token: any;
    tokenMeta: any;
    apiToken: TokenModel | undefined;
    bondingPair: any;
    bondingState: BondingPairTypes.State;
    feeCollectorState: FeeCollectorTypes.State | undefined;
    dexPairState: DexPairTypes.State | undefined;
    mcap: number;
}

const TradePageStats: React.FC<TradePageStatsProps> = ({
    token,
    tokenMeta,
    bondingPair,
    apiToken,
    bondingState,
    feeCollectorState,
    mcap,
    dexPairState
}) => {
    const logoBaseUrl = "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/";
    const { alphPrice } = useAppContext();
    const mcapCal = (Math.round(mcap * alphPrice * 100) / 100);
    const graduatePercent = (mcap / 20000) * 100;
    const { account, signer } = useWallet();
    const socials = parseSocials(tokenMeta.socials);

    const claimFee = function () {
        if (account && feeCollectorState && signer) {
            FeeCollector.at(feeCollectorState.address).transact.harvest({ signer });
        }
    };

    return (
        <>
            <div className="purple-border">
                <Card>
                    <CardBody>
                        <Row className="g-3 mb-3">
                            <Col xs={12} className="d-flex align-items-center">
                                <Image
                                    src={`${logoBaseUrl}${hexToStr(tokenMeta.logo)}`}
                                    alt={hexToStr(token.name)}
                                    style={{ maxHeight: "80px", objectFit: "contain" }}
                                    className="me-3"
                                />
                                <div>
                                    <Card.Title as="h5" className="mb-1">
                                        {hexToStr(token.name)} <small>({hexToStr(token.symbol)})</small>
                                    </Card.Title>
                                    <Card.Text className="mt-2 mb-2" style={{ fontSize: "0.9rem" }}>
                                        <Image
                                            src={formatImageSource(tokenMeta.owner, "")}
                                            roundedCircle
                                            width={40}
                                            height={40}
                                            alt="Profile"
                                            className="me-2"
                                        />
                                        {formatAddress(tokenMeta.owner, undefined)}
                                    </Card.Text>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card.Text className="mb-2" style={{ fontSize: "0.9rem" }}>
                                    {hexToStr(tokenMeta.description ?? "")}
                                </Card.Text>
                                {!dexPairState ? (
                                    <div className="d-flex flex-column align-items-left socials mb-3 mt-3">
                                        <div style={{ fontSize: "0.9rem", textAlign: "left" }}>
                                            <p>Graduation at <strong>${(20000 * alphPrice).toFixed(2)}</strong> market cap</p>
                                            <p>The Bonding Curve begins at <strong>${(1000 * alphPrice).toFixed(2)}</strong></p>
                                            <p>Current Market Cap: <strong>${mcapCal}</strong></p>
                                        </div>
                                        <ProgressBar
                                            now={graduatePercent}
                                            label={`${graduatePercent.toFixed(2)}%`}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                ) : <></>}
                                <div className="d-flex align-items-center socials">
                                    {socials.twitter &&
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="me-2"
                                            href={socials.twitter}
                                            target="_blank"
                                        >
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </Button>
                                    }
                                    {socials.telegram &&
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="me-2"
                                            href={socials.telegram}
                                            target="_blank"
                                        >
                                            <FontAwesomeIcon icon={faTelegram} />
                                        </Button>
                                    }
                                    {socials.discord &&
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="me-2"
                                            href={socials.discord}
                                            target="_blank"
                                        >
                                            <FontAwesomeIcon icon={faDiscord} />
                                        </Button>
                                    }
                                    <CopyButtonWithTooltip account={account} tokenId={bondingState.fields.tokenId} />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <div className='mt-3 purple-border'>
                <Card>
                    <CardBody className="p-0">
                        <div className="table-responsive">
                            <Table striped hover variant='dark' responsive>
                                {dexPairState ?
                                    <tbody>
                                        <tr>
                                            <td className="ps-3">State: </td>
                                            <td className="ps-3">AMM DEX</td>
                                        </tr>
                                        <tr>
                                            <td className="ps-3">Total Supply: </td>
                                            <td className="ps-3">1,000,000,000</td>
                                        </tr>
                                        {apiToken &&
                                            <>
                                                <tr>
                                                    <td className="ps-3">Market Cap: </td>
                                                    <td className="ps-3">${formatMcap(apiToken.marketCap, alphPrice)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-3">Volume: </td>
                                                    <td className="ps-3">${formatMcap(apiToken.volumeDaily, alphPrice)}</td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td className="ps-3">$ALPH Liquidity: </td>
                                            <td className="ps-3">{prettifyTokenAmount(dexPairState.fields.reserve0, 18)}</td>
                                        </tr>
                                        <tr>
                                            <td className="ps-3">${hexToStr(token.symbol)} Liquity: </td>
                                            <td className="ps-3">{prettifyTokenAmount(dexPairState.fields.reserve1, 18)}</td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td className="ps-3">State: </td>
                                            <td className="ps-3">Bonding Curve</td>
                                        </tr>
                                        <tr>
                                            <td className="ps-3">Total Supply: </td>
                                            <td className="ps-3">1,000,000,000</td>
                                        </tr>
                                        {apiToken &&
                                            <>
                                                <tr>
                                                    <td className="ps-3">Market Cap: </td>
                                                    <td className="ps-3">${formatMcap(apiToken.marketCap, alphPrice)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-3">Volume: </td>
                                                    <td className="ps-3">${formatMcap(apiToken.volumeDaily, alphPrice)}</td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td className="ps-3">$ALPH Bonded: </td>
                                            <td className="ps-3">{prettifyTokenAmount(bondingState.fields.totalBidToken, 18)}</td>
                                        </tr>
                                        <tr>
                                            <td className="ps-3">${hexToStr(token.symbol)} Left: </td>
                                            <td className="ps-3">{prettifyTokenAmount((800000000n * 10n ** 18n) - bondingState.fields.tokensSold, 18)}</td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <div className='mt-3 purple-border'>
                <Card>
                    <Card.Header className="latest-coins-header">🔥 TOP HOLDERS</Card.Header>
                    <CardBody className="p-0">
                        <TopHolderTable tokenId={bondingState.fields.tokenId} />
                    </CardBody>
                </Card>
            </div>
            {feeCollectorState && account && account.address === tokenMeta.owner && (
                <div className='mt-3 purple-border'>
                    <Card>
                        <Card.Header className="latest-coins-header">🌟 Creator Controls</Card.Header>
                        <CardBody className="p-3">
                            <h5>Fee Collected: {prettifyTokenAmount(feeCollectorState.fields.tradeFeeCollected, 18)} ALPH</h5>

                            <Col xs={12} className="d-flex align-items-center">
                                <Button variant="primary" onClick={claimFee} className="me-3">Claim!</Button>
                                <ModalMemeCreate tokenId={bondingState.fields.tokenId} />
                            </Col>
                        </CardBody>
                    </Card>
                </div>
            )}
        </>
    );
};

export default TradePageStats;