/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as BondingPairContractJson } from "../trade/BondingPair.ral.json";
import { getContractByCodeHash } from "./contracts";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";

// Custom types for the contract
export namespace BondingPairTypes {
  export type Fields = {
    tokenLauncher: HexString;
    router: HexString;
    protocolFeeCollector: HexString;
    tokenId: HexString;
    bidTokenId: HexString;
    totalTokensForSale: bigint;
    totalTokens: bigint;
    creatorAddress: Address;
    k: bigint;
    a: bigint;
    tokensSold: bigint;
    totalBidToken: bigint;
    graduated: boolean;
  };

  export type State = ContractState<Fields>;

  export type TradeEvent = ContractEvent<{
    caller: Address;
    tokenAmount: bigint;
    bidTokenAmount: bigint;
  }>;

  export interface CallMethodTable {
    to64x64: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    from64x64: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    exp: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    exp_2: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    ln: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    log_2: {
      params: CallContractParams<{ x: bigint }>;
      result: CallContractResult<bigint>;
    };
    getTokenId: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    isGraduated: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<boolean>;
    };
    isReadyToGraduate: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<boolean>;
    };
    sell: {
      params: CallContractParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyFeeCollector: HexString;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    calcSell: {
      params: CallContractParams<{ tokenAmountIn: bigint }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    buy: {
      params: CallContractParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyFeeCollector: HexString;
      }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    calcBuy: {
      params: CallContractParams<{ bidTokenAmountIn: bigint }>;
      result: CallContractResult<[bigint, bigint, bigint]>;
    };
    totalPriceOfTokens: {
      params: CallContractParams<{ startToken: bigint; endToken: bigint }>;
      result: CallContractResult<bigint>;
    };
    graduate: {
      params: CallContractParams<{
        dexPairTemplateId: HexString;
        royaltyFeeCollector: HexString;
        userCaller: Address;
      }>;
      result: CallContractResult<HexString>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    to64x64: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    from64x64: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    exp: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    exp_2: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    ln: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    log_2: {
      params: SignExecuteContractMethodParams<{ x: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    getTokenId: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    isGraduated: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    isReadyToGraduate: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    sell: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyFeeCollector: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    calcSell: {
      params: SignExecuteContractMethodParams<{ tokenAmountIn: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    buy: {
      params: SignExecuteContractMethodParams<{
        caller: Address;
        tokenAmountIn: bigint;
        royaltyFeeCollector: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    calcBuy: {
      params: SignExecuteContractMethodParams<{ bidTokenAmountIn: bigint }>;
      result: SignExecuteScriptTxResult;
    };
    totalPriceOfTokens: {
      params: SignExecuteContractMethodParams<{
        startToken: bigint;
        endToken: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
    graduate: {
      params: SignExecuteContractMethodParams<{
        dexPairTemplateId: HexString;
        royaltyFeeCollector: HexString;
        userCaller: Address;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  BondingPairInstance,
  BondingPairTypes.Fields
> {
  encodeFields(fields: BondingPairTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = { Trade: 0 };
  consts = {
    SCALE_64: BigInt("18446744073709551616"),
    SCALE_18: BigInt("1000000000000000000"),
    LN2: BigInt("235865763225513294137944142764154484399"),
    BASIS_POINTS_DIVISOR: BigInt("10000"),
    ROYALTY_FEE: BigInt("15"),
    PLATFORM_FEE: BigInt("85"),
    GRADUATION_FEE: BigInt("100000000000000000000"),
    FixedPointMathErrorCodes: {
      INVALID_INPUT: BigInt("100000"),
      OVERFLOW: BigInt("100001"),
    },
    ErrorCodes: {
      Unauthorized: BigInt("1"),
      TooEarlyToGraduate: BigInt("2"),
      ReadyToGraduate: BigInt("3"),
      CreatorBuysFirst: BigInt("4"),
      NotEnoughTokensLeft: BigInt("5"),
      InvalidTokenSelection: BigInt("6"),
      AmountTooSmall: BigInt("7"),
    },
  };

  at(address: string): BondingPairInstance {
    return new BondingPairInstance(address);
  }

  tests = {
    to64x64: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "to64x64", params, getContractByCodeHash);
    },
    from64x64: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "from64x64", params, getContractByCodeHash);
    },
    exp: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "exp", params, getContractByCodeHash);
    },
    exp_2: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "exp_2", params, getContractByCodeHash);
    },
    ln: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "ln", params, getContractByCodeHash);
    },
    log_2: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { x: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "log_2", params, getContractByCodeHash);
    },
    getTokenId: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getTokenId", params, getContractByCodeHash);
    },
    isGraduated: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<boolean>> => {
      return testMethod(this, "isGraduated", params, getContractByCodeHash);
    },
    isReadyToGraduate: async (
      params: Omit<
        TestContractParamsWithoutMaps<BondingPairTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<boolean>> => {
      return testMethod(
        this,
        "isReadyToGraduate",
        params,
        getContractByCodeHash
      );
    },
    sell: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        {
          caller: Address;
          tokenAmountIn: bigint;
          royaltyFeeCollector: HexString;
        }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "sell", params, getContractByCodeHash);
    },
    calcSell: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { tokenAmountIn: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "calcSell", params, getContractByCodeHash);
    },
    buy: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        {
          caller: Address;
          tokenAmountIn: bigint;
          royaltyFeeCollector: HexString;
        }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "buy", params, getContractByCodeHash);
    },
    calcBuy: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { bidTokenAmountIn: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<[bigint, bigint, bigint]>> => {
      return testMethod(this, "calcBuy", params, getContractByCodeHash);
    },
    totalPriceOfTokens: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        { startToken: bigint; endToken: bigint }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "totalPriceOfTokens",
        params,
        getContractByCodeHash
      );
    },
    graduate: async (
      params: TestContractParamsWithoutMaps<
        BondingPairTypes.Fields,
        {
          dexPairTemplateId: HexString;
          royaltyFeeCollector: HexString;
          userCaller: Address;
        }
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "graduate", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: BondingPairTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const BondingPair = new Factory(
  Contract.fromJson(
    BondingPairContractJson,
    "",
    "38fb19a70c8e44ba3c5601bf42848f56cd68e5deafb4aa5f536f8541715c32cd",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class BondingPairInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<BondingPairTypes.State> {
    return fetchContractState(BondingPair, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeTradeEvent(
    options: EventSubscribeOptions<BondingPairTypes.TradeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      BondingPair.contract,
      this,
      options,
      "Trade",
      fromCount
    );
  }

  view = {
    to64x64: async (
      params: BondingPairTypes.CallMethodParams<"to64x64">
    ): Promise<BondingPairTypes.CallMethodResult<"to64x64">> => {
      return callMethod(
        BondingPair,
        this,
        "to64x64",
        params,
        getContractByCodeHash
      );
    },
    from64x64: async (
      params: BondingPairTypes.CallMethodParams<"from64x64">
    ): Promise<BondingPairTypes.CallMethodResult<"from64x64">> => {
      return callMethod(
        BondingPair,
        this,
        "from64x64",
        params,
        getContractByCodeHash
      );
    },
    exp: async (
      params: BondingPairTypes.CallMethodParams<"exp">
    ): Promise<BondingPairTypes.CallMethodResult<"exp">> => {
      return callMethod(
        BondingPair,
        this,
        "exp",
        params,
        getContractByCodeHash
      );
    },
    exp_2: async (
      params: BondingPairTypes.CallMethodParams<"exp_2">
    ): Promise<BondingPairTypes.CallMethodResult<"exp_2">> => {
      return callMethod(
        BondingPair,
        this,
        "exp_2",
        params,
        getContractByCodeHash
      );
    },
    ln: async (
      params: BondingPairTypes.CallMethodParams<"ln">
    ): Promise<BondingPairTypes.CallMethodResult<"ln">> => {
      return callMethod(BondingPair, this, "ln", params, getContractByCodeHash);
    },
    log_2: async (
      params: BondingPairTypes.CallMethodParams<"log_2">
    ): Promise<BondingPairTypes.CallMethodResult<"log_2">> => {
      return callMethod(
        BondingPair,
        this,
        "log_2",
        params,
        getContractByCodeHash
      );
    },
    getTokenId: async (
      params?: BondingPairTypes.CallMethodParams<"getTokenId">
    ): Promise<BondingPairTypes.CallMethodResult<"getTokenId">> => {
      return callMethod(
        BondingPair,
        this,
        "getTokenId",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    isGraduated: async (
      params?: BondingPairTypes.CallMethodParams<"isGraduated">
    ): Promise<BondingPairTypes.CallMethodResult<"isGraduated">> => {
      return callMethod(
        BondingPair,
        this,
        "isGraduated",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    isReadyToGraduate: async (
      params?: BondingPairTypes.CallMethodParams<"isReadyToGraduate">
    ): Promise<BondingPairTypes.CallMethodResult<"isReadyToGraduate">> => {
      return callMethod(
        BondingPair,
        this,
        "isReadyToGraduate",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    sell: async (
      params: BondingPairTypes.CallMethodParams<"sell">
    ): Promise<BondingPairTypes.CallMethodResult<"sell">> => {
      return callMethod(
        BondingPair,
        this,
        "sell",
        params,
        getContractByCodeHash
      );
    },
    calcSell: async (
      params: BondingPairTypes.CallMethodParams<"calcSell">
    ): Promise<BondingPairTypes.CallMethodResult<"calcSell">> => {
      return callMethod(
        BondingPair,
        this,
        "calcSell",
        params,
        getContractByCodeHash
      );
    },
    buy: async (
      params: BondingPairTypes.CallMethodParams<"buy">
    ): Promise<BondingPairTypes.CallMethodResult<"buy">> => {
      return callMethod(
        BondingPair,
        this,
        "buy",
        params,
        getContractByCodeHash
      );
    },
    calcBuy: async (
      params: BondingPairTypes.CallMethodParams<"calcBuy">
    ): Promise<BondingPairTypes.CallMethodResult<"calcBuy">> => {
      return callMethod(
        BondingPair,
        this,
        "calcBuy",
        params,
        getContractByCodeHash
      );
    },
    totalPriceOfTokens: async (
      params: BondingPairTypes.CallMethodParams<"totalPriceOfTokens">
    ): Promise<BondingPairTypes.CallMethodResult<"totalPriceOfTokens">> => {
      return callMethod(
        BondingPair,
        this,
        "totalPriceOfTokens",
        params,
        getContractByCodeHash
      );
    },
    graduate: async (
      params: BondingPairTypes.CallMethodParams<"graduate">
    ): Promise<BondingPairTypes.CallMethodResult<"graduate">> => {
      return callMethod(
        BondingPair,
        this,
        "graduate",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    to64x64: async (
      params: BondingPairTypes.SignExecuteMethodParams<"to64x64">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"to64x64">> => {
      return signExecuteMethod(BondingPair, this, "to64x64", params);
    },
    from64x64: async (
      params: BondingPairTypes.SignExecuteMethodParams<"from64x64">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"from64x64">> => {
      return signExecuteMethod(BondingPair, this, "from64x64", params);
    },
    exp: async (
      params: BondingPairTypes.SignExecuteMethodParams<"exp">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"exp">> => {
      return signExecuteMethod(BondingPair, this, "exp", params);
    },
    exp_2: async (
      params: BondingPairTypes.SignExecuteMethodParams<"exp_2">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"exp_2">> => {
      return signExecuteMethod(BondingPair, this, "exp_2", params);
    },
    ln: async (
      params: BondingPairTypes.SignExecuteMethodParams<"ln">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"ln">> => {
      return signExecuteMethod(BondingPair, this, "ln", params);
    },
    log_2: async (
      params: BondingPairTypes.SignExecuteMethodParams<"log_2">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"log_2">> => {
      return signExecuteMethod(BondingPair, this, "log_2", params);
    },
    getTokenId: async (
      params: BondingPairTypes.SignExecuteMethodParams<"getTokenId">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"getTokenId">> => {
      return signExecuteMethod(BondingPair, this, "getTokenId", params);
    },
    isGraduated: async (
      params: BondingPairTypes.SignExecuteMethodParams<"isGraduated">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"isGraduated">> => {
      return signExecuteMethod(BondingPair, this, "isGraduated", params);
    },
    isReadyToGraduate: async (
      params: BondingPairTypes.SignExecuteMethodParams<"isReadyToGraduate">
    ): Promise<
      BondingPairTypes.SignExecuteMethodResult<"isReadyToGraduate">
    > => {
      return signExecuteMethod(BondingPair, this, "isReadyToGraduate", params);
    },
    sell: async (
      params: BondingPairTypes.SignExecuteMethodParams<"sell">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"sell">> => {
      return signExecuteMethod(BondingPair, this, "sell", params);
    },
    calcSell: async (
      params: BondingPairTypes.SignExecuteMethodParams<"calcSell">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"calcSell">> => {
      return signExecuteMethod(BondingPair, this, "calcSell", params);
    },
    buy: async (
      params: BondingPairTypes.SignExecuteMethodParams<"buy">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"buy">> => {
      return signExecuteMethod(BondingPair, this, "buy", params);
    },
    calcBuy: async (
      params: BondingPairTypes.SignExecuteMethodParams<"calcBuy">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"calcBuy">> => {
      return signExecuteMethod(BondingPair, this, "calcBuy", params);
    },
    totalPriceOfTokens: async (
      params: BondingPairTypes.SignExecuteMethodParams<"totalPriceOfTokens">
    ): Promise<
      BondingPairTypes.SignExecuteMethodResult<"totalPriceOfTokens">
    > => {
      return signExecuteMethod(BondingPair, this, "totalPriceOfTokens", params);
    },
    graduate: async (
      params: BondingPairTypes.SignExecuteMethodParams<"graduate">
    ): Promise<BondingPairTypes.SignExecuteMethodResult<"graduate">> => {
      return signExecuteMethod(BondingPair, this, "graduate", params);
    },
  };

  async multicall<Calls extends BondingPairTypes.MultiCallParams>(
    calls: Calls
  ): Promise<BondingPairTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends BondingPairTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<BondingPairTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | BondingPairTypes.MultiCallParams
      | BondingPairTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      BondingPair,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
