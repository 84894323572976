import React from 'react';
import Footer from '../Footer';
import Header from '../Header';


const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

   return (
      <div className=' background-image-to-color'>
         <Header></Header>
         {children}
         <Footer></Footer>
      </div>
   )
};

export default Layout;