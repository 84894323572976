import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Spinner, CardTitle } from 'react-bootstrap';
import { prettifyTokenAmount } from '@alephium/web3';
import { getContractConfig } from '../../services/utils';
import { formatAddress, formatImageSource, formatMcap, formatTimeAgo } from '../../components/utils/Formatters';
import './MemeWarsPage.css';
import { TokenModel } from 'src/models/token';
import { useAppContext } from 'src/context/AppContext';
import { Link, useLocation } from 'react-router-dom';

const MemeWarsPage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const roundNumber = parseInt(query.get("round") + "");
    const API_URL = process.env.REACT_APP_ANALYTICS_URL + (roundNumber < 3 ? `/api/competition/memewars/${roundNumber}` : `/api/competition/memewarstwo/${roundNumber}`);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [tokens, setTokens] = useState<TokenModel[]>([]);
    const [page, setPage] = useState(0);
    const [isLoadMoreEnabled, setIsLoadMoreEnabled] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const { alphPrice } = useAppContext();

    const loadStakers = async (newPage = 0) => {
        try {
            if (loading || loadingMore) return;
            if (newPage === 0) setLoading(true);
            else setLoadingMore(true);
            const response = await fetch(`${API_URL}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const resp = await response.json();

            if (resp.data.length < 10) {
                setIsLoadMoreEnabled(false);
            }

            if (newPage === 0) {
                setTokens(rankTokens(resp.data as TokenModel[]));
            } else {
                setTokens((prevStakers) => [...prevStakers, ...resp.data]);
            }
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreStakers = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        loadStakers(nextPage);
    };

    const rankTokens = (tokens: TokenModel[]) => {
        if (tokens.length === 0) return [];

        // Sort tokens by volume and assign scores
        const sortedByVolume = [...tokens].sort((a, b) => (b.volumeDaily ?? 0) - (a.volumeDaily ?? 0));
        const maxVolumeScore = 60;
        const volumeScores = new Map(
            sortedByVolume.map((token, index) => [token.id, maxVolumeScore * (1 - index / (tokens.length - 1))])
        );

        // Sort tokens by market cap and assign scores
        const sortedByMarketCap = [...tokens].sort((a, b) => (b.marketCap ?? 0) - (a.marketCap ?? 0));
        const maxMarketCapScore = 40;
        const marketCapScores = new Map(
            sortedByMarketCap.map((token, index) => [token.id, maxMarketCapScore * (1 - index / (tokens.length - 1))])
        );

        // Calculate final ranking score and sort by it
        return [...tokens]
            .map(token => ({
                token,
                rankScore: (volumeScores.get(token.id) ?? 0) + (marketCapScores.get(token.id) ?? 0),
            }))
            .sort((a, b) => b.rankScore - a.rankScore)
            .map(entry => entry.token); // Return only TokenModel objects
    };

    useEffect(() => {
        setPage(0);
        loadStakers();
    }, [roundNumber]);

    return (
        <Container fluid className="mt-5 mb-5">
            <Row>
                <Col>
                    <img
                        src="/images/promo/memewarsbaner.jpg"
                        width={'100%'}
                        className="mt-5 mb-3"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="pink-border mt-3">
                        <Card className="mainTable">
                            <CardTitle>
                                <div className="d-flex justify-content-between align-items-center ps-3 pt-3 pe-3">
                                    <h2>
                                        Round {query.get("round")}
                                    </h2>
                                    {query.get("round") && roundNumber > 1 && (
                                        <Link
                                            to={`/competition/memewars?round=${roundNumber - 1}`}
                                            className='me-3'
                                        >
                                            <small className='yellow-text'>See Round {roundNumber - 1}</small>
                                        </Link>
                                    )}
                                </div>
                            </CardTitle>
                            <CardBody>
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
                                        <Spinner animation="border" />
                                    </div>
                                ) : (
                                    tokens.map((val, index) => (
                                        <div
                                            key={index}
                                            className="d-flex flex-column flex-md-row align-items-center py-3 px-3 rounded"
                                        >
                                            <div
                                                className="mb-2 mb-md-0 me-md-3 d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    backgroundColor: '#3C2757',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                <span className="text-white fw-bold">{index + 1}</span>
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column mb-2 mb-md-0">
                                                <div className="d-flex flex-column flex-md-row align-items-center">
                                                    <Link
                                                        to={`/trade?tokenId=${val.id}`}
                                                        rel="noopener noreferrer"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <img
                                                            src={formatImageSource(val.id, val.logo + "")}
                                                            alt="avatar"
                                                            className="rounded-circle me-3"
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                        <span className="white-text fw-bold">
                                                            {`${val.name}  (${val.symbol})`}
                                                        </span>
                                                        <span
                                                            className="ms-2 text-decoration-none text-info"
                                                            style={{ fontSize: '0.8rem' }}>
                                                            ↗
                                                        </span>
                                                    </Link>
                                                    <div
                                                        className="ms-md-3 mt-1 white-muted-text"
                                                        style={{ fontSize: '0.9rem' }}
                                                    >
                                                        <span className="me-4">
                                                            Created: {formatTimeAgo(val.createdTimestamp)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="text-center mb-2 mb-md-0"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <div
                                                    className="text-center mt-2"
                                                >
                                                    {((val.volumeDaily ? val.volumeDaily : 0) * alphPrice) > 25000 ? (
                                                        <span className="text-success">&#10003; Volume</span> // Green checkmark
                                                    ) : (
                                                        <span className="text-secondary">&times; Volume</span> // Gray checkmark
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="text-center mb-2 mb-md-0"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <div
                                                    className="text-center mt-2"
                                                >
                                                    {val.dexPair ? (
                                                        <span className="text-success">&#10003; Graduated</span> // Green checkmark
                                                    ) : (
                                                        <span className="text-secondary">&times; Graduated</span> // Gray checkmark
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="text-center mb-2 mb-md-0"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <div
                                                    className="yellow-text fw-bold"
                                                    style={{ fontSize: '1.2rem' }}
                                                >
                                                    ${formatMcap(val.volumeDaily, alphPrice)}
                                                </div>
                                                <div
                                                    className="white-muted-text"
                                                    style={{ fontSize: '0.8rem' }}
                                                >
                                                    Volume
                                                </div>
                                            </div>
                                            <div
                                                className="text-center"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <div
                                                    className="yellow-text fw-bold"
                                                    style={{ fontSize: '1.2rem' }}
                                                >
                                                    ${formatMcap(val.marketCap, alphPrice)}
                                                </div>
                                                <div
                                                    className="white-muted-text"
                                                    style={{ fontSize: '0.8rem' }}
                                                >
                                                    Marketcap
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {/* {isLoadMoreEnabled && (
                                    <div className="d-flex justify-content-center mt-3 mb-3">
                                        <Button
                                            variant="outline-primary"
                                            onClick={loadMoreStakers}
                                            disabled={loadingMore}
                                        >
                                            {loadingMore && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                            )}
                                            Load More
                                        </Button>
                                    </div>
                                )} */}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
export default MemeWarsPage;