/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as FeeHandlerContractJson } from "../onion/FeeHandler.ral.json";
import { getContractByCodeHash } from "./contracts";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";

// Custom types for the contract
export namespace FeeHandlerTypes {
  export type Fields = {
    feeCollector: HexString;
    manualCollector: Address;
    collectedFee: bigint;
    upgradableSettings: UpgradableSettings;
  };

  export type State = ContractState<Fields>;

  export type ChangeOwnerCommenceEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type ChangeOwnerApplyEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type MigrateCommenceEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateWithFieldsApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
    changeImmFieldsEncoded: HexString;
    changeMutFieldsEncoded: HexString;
  }>;

  export interface CallMethodTable {
    changeOwner: {
      params: CallContractParams<{ changeOwner: Address }>;
      result: CallContractResult<null>;
    };
    migrate: {
      params: CallContractParams<{ changeCode: HexString }>;
      result: CallContractResult<null>;
    };
    changeOwnerApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateWithFieldsApply: {
      params: CallContractParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: CallContractResult<null>;
    };
    resetUpgrade: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    getUpgradeDelay: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getNewOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getUpgradeCommenced: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getNewCode: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    resetFields: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertOnlyOwner: {
      params: CallContractParams<{ caller: Address }>;
      result: CallContractResult<null>;
    };
    assertUpgradeNotPending: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    collect: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    withdraw: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    setManualCollector: {
      params: CallContractParams<{ newManualCollector: Address }>;
      result: CallContractResult<null>;
    };
    setFeeCollector: {
      params: CallContractParams<{ newFeeCollector: HexString }>;
      result: CallContractResult<null>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    changeOwner: {
      params: SignExecuteContractMethodParams<{ changeOwner: Address }>;
      result: SignExecuteScriptTxResult;
    };
    migrate: {
      params: SignExecuteContractMethodParams<{ changeCode: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    changeOwnerApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateWithFieldsApply: {
      params: SignExecuteContractMethodParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    resetUpgrade: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeDelay: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeCommenced: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewCode: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    resetFields: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertOnlyOwner: {
      params: SignExecuteContractMethodParams<{ caller: Address }>;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeNotPending: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    collect: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    withdraw: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    setManualCollector: {
      params: SignExecuteContractMethodParams<{ newManualCollector: Address }>;
      result: SignExecuteScriptTxResult;
    };
    setFeeCollector: {
      params: SignExecuteContractMethodParams<{ newFeeCollector: HexString }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  FeeHandlerInstance,
  FeeHandlerTypes.Fields
> {
  encodeFields(fields: FeeHandlerTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = {
    ChangeOwnerCommence: 0,
    ChangeOwnerApply: 1,
    MigrateCommence: 2,
    MigrateApply: 3,
    MigrateWithFieldsApply: 4,
  };
  consts = {
    UpgradeErrorCodes: {
      Forbidden: BigInt("13000"),
      UpgradePending: BigInt("13001"),
      UpgradeNotPending: BigInt("13002"),
      UpgradeDelayNotExpired: BigInt("13003"),
      MigrateNotPending: BigInt("13004"),
      MigrateWithFieldsNotPending: BigInt("13005"),
      ChangeOwnerNotPending: BigInt("13006"),
    },
  };

  at(address: string): FeeHandlerInstance {
    return new FeeHandlerInstance(address);
  }

  tests = {
    changeOwner: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { changeOwner: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "changeOwner", params, getContractByCodeHash);
    },
    migrate: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { changeCode: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrate", params, getContractByCodeHash);
    },
    changeOwnerApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "changeOwnerApply",
        params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrateApply", params, getContractByCodeHash);
    },
    migrateWithFieldsApply: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { newImmFieldsEncoded: HexString; newMutFieldsEncoded: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetUpgrade", params, getContractByCodeHash);
    },
    getUpgradeDelay: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getUpgradeDelay", params, getContractByCodeHash);
    },
    getOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getOwner", params, getContractByCodeHash);
    },
    getNewOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getNewOwner", params, getContractByCodeHash);
    },
    getUpgradeCommenced: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getUpgradeCommenced",
        params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getNewCode", params, getContractByCodeHash);
    },
    resetFields: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetFields", params, getContractByCodeHash);
    },
    assertOnlyOwner: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { caller: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "assertOnlyOwner", params, getContractByCodeHash);
    },
    assertUpgradeNotPending: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeNotPending",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeDelayElapsed",
        params,
        getContractByCodeHash
      );
    },
    collect: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "collect", params, getContractByCodeHash);
    },
    withdraw: async (
      params: Omit<
        TestContractParamsWithoutMaps<FeeHandlerTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "withdraw", params, getContractByCodeHash);
    },
    setManualCollector: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { newManualCollector: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "setManualCollector",
        params,
        getContractByCodeHash
      );
    },
    setFeeCollector: async (
      params: TestContractParamsWithoutMaps<
        FeeHandlerTypes.Fields,
        { newFeeCollector: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "setFeeCollector", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: FeeHandlerTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const FeeHandler = new Factory(
  Contract.fromJson(
    FeeHandlerContractJson,
    "",
    "5a9e4a6d03f7045a9bbf66449155cd43ce759474ab1e60ed4474e2d77c0ee4a3",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class FeeHandlerInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<FeeHandlerTypes.State> {
    return fetchContractState(FeeHandler, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeChangeOwnerCommenceEvent(
    options: EventSubscribeOptions<FeeHandlerTypes.ChangeOwnerCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeHandler.contract,
      this,
      options,
      "ChangeOwnerCommence",
      fromCount
    );
  }

  subscribeChangeOwnerApplyEvent(
    options: EventSubscribeOptions<FeeHandlerTypes.ChangeOwnerApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeHandler.contract,
      this,
      options,
      "ChangeOwnerApply",
      fromCount
    );
  }

  subscribeMigrateCommenceEvent(
    options: EventSubscribeOptions<FeeHandlerTypes.MigrateCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeHandler.contract,
      this,
      options,
      "MigrateCommence",
      fromCount
    );
  }

  subscribeMigrateApplyEvent(
    options: EventSubscribeOptions<FeeHandlerTypes.MigrateApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeHandler.contract,
      this,
      options,
      "MigrateApply",
      fromCount
    );
  }

  subscribeMigrateWithFieldsApplyEvent(
    options: EventSubscribeOptions<FeeHandlerTypes.MigrateWithFieldsApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      FeeHandler.contract,
      this,
      options,
      "MigrateWithFieldsApply",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      | FeeHandlerTypes.ChangeOwnerCommenceEvent
      | FeeHandlerTypes.ChangeOwnerApplyEvent
      | FeeHandlerTypes.MigrateCommenceEvent
      | FeeHandlerTypes.MigrateApplyEvent
      | FeeHandlerTypes.MigrateWithFieldsApplyEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(
      FeeHandler.contract,
      this,
      options,
      fromCount
    );
  }

  view = {
    changeOwner: async (
      params: FeeHandlerTypes.CallMethodParams<"changeOwner">
    ): Promise<FeeHandlerTypes.CallMethodResult<"changeOwner">> => {
      return callMethod(
        FeeHandler,
        this,
        "changeOwner",
        params,
        getContractByCodeHash
      );
    },
    migrate: async (
      params: FeeHandlerTypes.CallMethodParams<"migrate">
    ): Promise<FeeHandlerTypes.CallMethodResult<"migrate">> => {
      return callMethod(
        FeeHandler,
        this,
        "migrate",
        params,
        getContractByCodeHash
      );
    },
    changeOwnerApply: async (
      params?: FeeHandlerTypes.CallMethodParams<"changeOwnerApply">
    ): Promise<FeeHandlerTypes.CallMethodResult<"changeOwnerApply">> => {
      return callMethod(
        FeeHandler,
        this,
        "changeOwnerApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params?: FeeHandlerTypes.CallMethodParams<"migrateApply">
    ): Promise<FeeHandlerTypes.CallMethodResult<"migrateApply">> => {
      return callMethod(
        FeeHandler,
        this,
        "migrateApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateWithFieldsApply: async (
      params: FeeHandlerTypes.CallMethodParams<"migrateWithFieldsApply">
    ): Promise<FeeHandlerTypes.CallMethodResult<"migrateWithFieldsApply">> => {
      return callMethod(
        FeeHandler,
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params?: FeeHandlerTypes.CallMethodParams<"resetUpgrade">
    ): Promise<FeeHandlerTypes.CallMethodResult<"resetUpgrade">> => {
      return callMethod(
        FeeHandler,
        this,
        "resetUpgrade",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeDelay: async (
      params?: FeeHandlerTypes.CallMethodParams<"getUpgradeDelay">
    ): Promise<FeeHandlerTypes.CallMethodResult<"getUpgradeDelay">> => {
      return callMethod(
        FeeHandler,
        this,
        "getUpgradeDelay",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getOwner: async (
      params?: FeeHandlerTypes.CallMethodParams<"getOwner">
    ): Promise<FeeHandlerTypes.CallMethodResult<"getOwner">> => {
      return callMethod(
        FeeHandler,
        this,
        "getOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewOwner: async (
      params?: FeeHandlerTypes.CallMethodParams<"getNewOwner">
    ): Promise<FeeHandlerTypes.CallMethodResult<"getNewOwner">> => {
      return callMethod(
        FeeHandler,
        this,
        "getNewOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeCommenced: async (
      params?: FeeHandlerTypes.CallMethodParams<"getUpgradeCommenced">
    ): Promise<FeeHandlerTypes.CallMethodResult<"getUpgradeCommenced">> => {
      return callMethod(
        FeeHandler,
        this,
        "getUpgradeCommenced",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params?: FeeHandlerTypes.CallMethodParams<"getNewCode">
    ): Promise<FeeHandlerTypes.CallMethodResult<"getNewCode">> => {
      return callMethod(
        FeeHandler,
        this,
        "getNewCode",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    resetFields: async (
      params?: FeeHandlerTypes.CallMethodParams<"resetFields">
    ): Promise<FeeHandlerTypes.CallMethodResult<"resetFields">> => {
      return callMethod(
        FeeHandler,
        this,
        "resetFields",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertOnlyOwner: async (
      params: FeeHandlerTypes.CallMethodParams<"assertOnlyOwner">
    ): Promise<FeeHandlerTypes.CallMethodResult<"assertOnlyOwner">> => {
      return callMethod(
        FeeHandler,
        this,
        "assertOnlyOwner",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeNotPending: async (
      params?: FeeHandlerTypes.CallMethodParams<"assertUpgradeNotPending">
    ): Promise<FeeHandlerTypes.CallMethodResult<"assertUpgradeNotPending">> => {
      return callMethod(
        FeeHandler,
        this,
        "assertUpgradeNotPending",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params?: FeeHandlerTypes.CallMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      FeeHandlerTypes.CallMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return callMethod(
        FeeHandler,
        this,
        "assertUpgradeDelayElapsed",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    collect: async (
      params?: FeeHandlerTypes.CallMethodParams<"collect">
    ): Promise<FeeHandlerTypes.CallMethodResult<"collect">> => {
      return callMethod(
        FeeHandler,
        this,
        "collect",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    withdraw: async (
      params?: FeeHandlerTypes.CallMethodParams<"withdraw">
    ): Promise<FeeHandlerTypes.CallMethodResult<"withdraw">> => {
      return callMethod(
        FeeHandler,
        this,
        "withdraw",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    setManualCollector: async (
      params: FeeHandlerTypes.CallMethodParams<"setManualCollector">
    ): Promise<FeeHandlerTypes.CallMethodResult<"setManualCollector">> => {
      return callMethod(
        FeeHandler,
        this,
        "setManualCollector",
        params,
        getContractByCodeHash
      );
    },
    setFeeCollector: async (
      params: FeeHandlerTypes.CallMethodParams<"setFeeCollector">
    ): Promise<FeeHandlerTypes.CallMethodResult<"setFeeCollector">> => {
      return callMethod(
        FeeHandler,
        this,
        "setFeeCollector",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    changeOwner: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"changeOwner">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"changeOwner">> => {
      return signExecuteMethod(FeeHandler, this, "changeOwner", params);
    },
    migrate: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"migrate">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"migrate">> => {
      return signExecuteMethod(FeeHandler, this, "migrate", params);
    },
    changeOwnerApply: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"changeOwnerApply">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"changeOwnerApply">> => {
      return signExecuteMethod(FeeHandler, this, "changeOwnerApply", params);
    },
    migrateApply: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"migrateApply">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"migrateApply">> => {
      return signExecuteMethod(FeeHandler, this, "migrateApply", params);
    },
    migrateWithFieldsApply: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"migrateWithFieldsApply">
    ): Promise<
      FeeHandlerTypes.SignExecuteMethodResult<"migrateWithFieldsApply">
    > => {
      return signExecuteMethod(
        FeeHandler,
        this,
        "migrateWithFieldsApply",
        params
      );
    },
    resetUpgrade: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"resetUpgrade">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"resetUpgrade">> => {
      return signExecuteMethod(FeeHandler, this, "resetUpgrade", params);
    },
    getUpgradeDelay: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"getUpgradeDelay">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"getUpgradeDelay">> => {
      return signExecuteMethod(FeeHandler, this, "getUpgradeDelay", params);
    },
    getOwner: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"getOwner">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"getOwner">> => {
      return signExecuteMethod(FeeHandler, this, "getOwner", params);
    },
    getNewOwner: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"getNewOwner">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"getNewOwner">> => {
      return signExecuteMethod(FeeHandler, this, "getNewOwner", params);
    },
    getUpgradeCommenced: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"getUpgradeCommenced">
    ): Promise<
      FeeHandlerTypes.SignExecuteMethodResult<"getUpgradeCommenced">
    > => {
      return signExecuteMethod(FeeHandler, this, "getUpgradeCommenced", params);
    },
    getNewCode: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"getNewCode">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"getNewCode">> => {
      return signExecuteMethod(FeeHandler, this, "getNewCode", params);
    },
    resetFields: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"resetFields">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"resetFields">> => {
      return signExecuteMethod(FeeHandler, this, "resetFields", params);
    },
    assertOnlyOwner: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"assertOnlyOwner">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"assertOnlyOwner">> => {
      return signExecuteMethod(FeeHandler, this, "assertOnlyOwner", params);
    },
    assertUpgradeNotPending: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"assertUpgradeNotPending">
    ): Promise<
      FeeHandlerTypes.SignExecuteMethodResult<"assertUpgradeNotPending">
    > => {
      return signExecuteMethod(
        FeeHandler,
        this,
        "assertUpgradeNotPending",
        params
      );
    },
    assertUpgradeDelayElapsed: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      FeeHandlerTypes.SignExecuteMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return signExecuteMethod(
        FeeHandler,
        this,
        "assertUpgradeDelayElapsed",
        params
      );
    },
    collect: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"collect">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"collect">> => {
      return signExecuteMethod(FeeHandler, this, "collect", params);
    },
    withdraw: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"withdraw">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"withdraw">> => {
      return signExecuteMethod(FeeHandler, this, "withdraw", params);
    },
    setManualCollector: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"setManualCollector">
    ): Promise<
      FeeHandlerTypes.SignExecuteMethodResult<"setManualCollector">
    > => {
      return signExecuteMethod(FeeHandler, this, "setManualCollector", params);
    },
    setFeeCollector: async (
      params: FeeHandlerTypes.SignExecuteMethodParams<"setFeeCollector">
    ): Promise<FeeHandlerTypes.SignExecuteMethodResult<"setFeeCollector">> => {
      return signExecuteMethod(FeeHandler, this, "setFeeCollector", params);
    },
  };

  async multicall<Calls extends FeeHandlerTypes.MultiCallParams>(
    calls: Calls
  ): Promise<FeeHandlerTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends FeeHandlerTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<FeeHandlerTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | FeeHandlerTypes.MultiCallParams
      | FeeHandlerTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      FeeHandler,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
