/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as OnionRouterContractJson } from "../trade/OnionRouter.ral.json";
import { getContractByCodeHash } from "./contracts";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";

// Custom types for the contract
export namespace OnionRouterTypes {
  export type Fields = {
    upgradableSettings: UpgradableSettings;
  };

  export type State = ContractState<Fields>;

  export type ChangeOwnerCommenceEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type ChangeOwnerApplyEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type MigrateCommenceEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateWithFieldsApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
    changeImmFieldsEncoded: HexString;
    changeMutFieldsEncoded: HexString;
  }>;
  export type TradeEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    tokenInId: HexString;
    amountIn: bigint;
    amountOut: bigint;
    platformFeeAmount: bigint;
    royaltyFeeAmount: bigint;
    royaltyRecipient: Address;
  }>;

  export interface CallMethodTable {
    changeOwner: {
      params: CallContractParams<{ changeOwner: Address }>;
      result: CallContractResult<null>;
    };
    migrate: {
      params: CallContractParams<{ changeCode: HexString }>;
      result: CallContractResult<null>;
    };
    changeOwnerApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateWithFieldsApply: {
      params: CallContractParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: CallContractResult<null>;
    };
    resetUpgrade: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    getUpgradeDelay: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getNewOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getUpgradeCommenced: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getNewCode: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    resetFields: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertOnlyOwner: {
      params: CallContractParams<{ caller: Address }>;
      result: CallContractResult<null>;
    };
    assertUpgradeNotPending: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    swapExactTokenForToken: {
      params: CallContractParams<{
        bondingPair: HexString;
        dexPair: HexString;
        tokenLauncher: HexString;
        commentTracker: HexString;
        profileTracker: HexString;
        caller: Address;
        tokenInId: HexString;
        amountIn: bigint;
        amountOutMin: bigint;
        deadline: bigint;
        comment: HexString;
        referrer: Address;
      }>;
      result: CallContractResult<bigint>;
    };
    simulateSwapExactTokenForToken: {
      params: CallContractParams<{
        bondingPair: HexString;
        dexPair: HexString;
        tokenInId: HexString;
        amountIn: bigint;
        slippage: bigint;
      }>;
      result: CallContractResult<[bigint, bigint, bigint, bigint]>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    changeOwner: {
      params: SignExecuteContractMethodParams<{ changeOwner: Address }>;
      result: SignExecuteScriptTxResult;
    };
    migrate: {
      params: SignExecuteContractMethodParams<{ changeCode: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    changeOwnerApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateWithFieldsApply: {
      params: SignExecuteContractMethodParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    resetUpgrade: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeDelay: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeCommenced: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewCode: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    resetFields: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertOnlyOwner: {
      params: SignExecuteContractMethodParams<{ caller: Address }>;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeNotPending: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    swapExactTokenForToken: {
      params: SignExecuteContractMethodParams<{
        bondingPair: HexString;
        dexPair: HexString;
        tokenLauncher: HexString;
        commentTracker: HexString;
        profileTracker: HexString;
        caller: Address;
        tokenInId: HexString;
        amountIn: bigint;
        amountOutMin: bigint;
        deadline: bigint;
        comment: HexString;
        referrer: Address;
      }>;
      result: SignExecuteScriptTxResult;
    };
    simulateSwapExactTokenForToken: {
      params: SignExecuteContractMethodParams<{
        bondingPair: HexString;
        dexPair: HexString;
        tokenInId: HexString;
        amountIn: bigint;
        slippage: bigint;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];
}

class Factory extends ContractFactory<
  OnionRouterInstance,
  OnionRouterTypes.Fields
> {
  encodeFields(fields: OnionRouterTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = {
    ChangeOwnerCommence: 0,
    ChangeOwnerApply: 1,
    MigrateCommence: 2,
    MigrateApply: 3,
    MigrateWithFieldsApply: 4,
    Trade: 5,
  };
  consts = {
    UpgradeErrorCodes: {
      Forbidden: BigInt("13000"),
      UpgradePending: BigInt("13001"),
      UpgradeNotPending: BigInt("13002"),
      UpgradeDelayNotExpired: BigInt("13003"),
      MigrateNotPending: BigInt("13004"),
      MigrateWithFieldsNotPending: BigInt("13005"),
      ChangeOwnerNotPending: BigInt("13006"),
    },
    RouterErrors: { Expired: BigInt("1"), SlippageTooHigh: BigInt("2") },
  };

  at(address: string): OnionRouterInstance {
    return new OnionRouterInstance(address);
  }

  tests = {
    changeOwner: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        { changeOwner: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "changeOwner", params, getContractByCodeHash);
    },
    migrate: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        { changeCode: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrate", params, getContractByCodeHash);
    },
    changeOwnerApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "changeOwnerApply",
        params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "migrateApply", params, getContractByCodeHash);
    },
    migrateWithFieldsApply: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        { newImmFieldsEncoded: HexString; newMutFieldsEncoded: HexString }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetUpgrade", params, getContractByCodeHash);
    },
    getUpgradeDelay: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(this, "getUpgradeDelay", params, getContractByCodeHash);
    },
    getOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getOwner", params, getContractByCodeHash);
    },
    getNewOwner: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<Address>> => {
      return testMethod(this, "getNewOwner", params, getContractByCodeHash);
    },
    getUpgradeCommenced: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "getUpgradeCommenced",
        params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<HexString>> => {
      return testMethod(this, "getNewCode", params, getContractByCodeHash);
    },
    resetFields: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "resetFields", params, getContractByCodeHash);
    },
    assertOnlyOwner: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        { caller: Address }
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(this, "assertOnlyOwner", params, getContractByCodeHash);
    },
    assertUpgradeNotPending: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeNotPending",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params: Omit<
        TestContractParamsWithoutMaps<OnionRouterTypes.Fields, never>,
        "testArgs"
      >
    ): Promise<TestContractResultWithoutMaps<null>> => {
      return testMethod(
        this,
        "assertUpgradeDelayElapsed",
        params,
        getContractByCodeHash
      );
    },
    swapExactTokenForToken: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        {
          bondingPair: HexString;
          dexPair: HexString;
          tokenLauncher: HexString;
          commentTracker: HexString;
          profileTracker: HexString;
          caller: Address;
          tokenInId: HexString;
          amountIn: bigint;
          amountOutMin: bigint;
          deadline: bigint;
          comment: HexString;
          referrer: Address;
        }
      >
    ): Promise<TestContractResultWithoutMaps<bigint>> => {
      return testMethod(
        this,
        "swapExactTokenForToken",
        params,
        getContractByCodeHash
      );
    },
    simulateSwapExactTokenForToken: async (
      params: TestContractParamsWithoutMaps<
        OnionRouterTypes.Fields,
        {
          bondingPair: HexString;
          dexPair: HexString;
          tokenInId: HexString;
          amountIn: bigint;
          slippage: bigint;
        }
      >
    ): Promise<
      TestContractResultWithoutMaps<[bigint, bigint, bigint, bigint]>
    > => {
      return testMethod(
        this,
        "simulateSwapExactTokenForToken",
        params,
        getContractByCodeHash
      );
    },
  };

  stateForTest(
    initFields: OnionRouterTypes.Fields,
    asset?: Asset,
    address?: string
  ) {
    return this.stateForTest_(initFields, asset, address, undefined);
  }
}

// Use this object to test and deploy the contract
export const OnionRouter = new Factory(
  Contract.fromJson(
    OnionRouterContractJson,
    "",
    "333646eb5fd44384550d0291de7a624dd300494a000082bf4cd82761e6f57154",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class OnionRouterInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  async fetchState(): Promise<OnionRouterTypes.State> {
    return fetchContractState(OnionRouter, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeChangeOwnerCommenceEvent(
    options: EventSubscribeOptions<OnionRouterTypes.ChangeOwnerCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "ChangeOwnerCommence",
      fromCount
    );
  }

  subscribeChangeOwnerApplyEvent(
    options: EventSubscribeOptions<OnionRouterTypes.ChangeOwnerApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "ChangeOwnerApply",
      fromCount
    );
  }

  subscribeMigrateCommenceEvent(
    options: EventSubscribeOptions<OnionRouterTypes.MigrateCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "MigrateCommence",
      fromCount
    );
  }

  subscribeMigrateApplyEvent(
    options: EventSubscribeOptions<OnionRouterTypes.MigrateApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "MigrateApply",
      fromCount
    );
  }

  subscribeMigrateWithFieldsApplyEvent(
    options: EventSubscribeOptions<OnionRouterTypes.MigrateWithFieldsApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "MigrateWithFieldsApply",
      fromCount
    );
  }

  subscribeTradeEvent(
    options: EventSubscribeOptions<OnionRouterTypes.TradeEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      OnionRouter.contract,
      this,
      options,
      "Trade",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      | OnionRouterTypes.ChangeOwnerCommenceEvent
      | OnionRouterTypes.ChangeOwnerApplyEvent
      | OnionRouterTypes.MigrateCommenceEvent
      | OnionRouterTypes.MigrateApplyEvent
      | OnionRouterTypes.MigrateWithFieldsApplyEvent
      | OnionRouterTypes.TradeEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(
      OnionRouter.contract,
      this,
      options,
      fromCount
    );
  }

  view = {
    changeOwner: async (
      params: OnionRouterTypes.CallMethodParams<"changeOwner">
    ): Promise<OnionRouterTypes.CallMethodResult<"changeOwner">> => {
      return callMethod(
        OnionRouter,
        this,
        "changeOwner",
        params,
        getContractByCodeHash
      );
    },
    migrate: async (
      params: OnionRouterTypes.CallMethodParams<"migrate">
    ): Promise<OnionRouterTypes.CallMethodResult<"migrate">> => {
      return callMethod(
        OnionRouter,
        this,
        "migrate",
        params,
        getContractByCodeHash
      );
    },
    changeOwnerApply: async (
      params?: OnionRouterTypes.CallMethodParams<"changeOwnerApply">
    ): Promise<OnionRouterTypes.CallMethodResult<"changeOwnerApply">> => {
      return callMethod(
        OnionRouter,
        this,
        "changeOwnerApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params?: OnionRouterTypes.CallMethodParams<"migrateApply">
    ): Promise<OnionRouterTypes.CallMethodResult<"migrateApply">> => {
      return callMethod(
        OnionRouter,
        this,
        "migrateApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateWithFieldsApply: async (
      params: OnionRouterTypes.CallMethodParams<"migrateWithFieldsApply">
    ): Promise<OnionRouterTypes.CallMethodResult<"migrateWithFieldsApply">> => {
      return callMethod(
        OnionRouter,
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params?: OnionRouterTypes.CallMethodParams<"resetUpgrade">
    ): Promise<OnionRouterTypes.CallMethodResult<"resetUpgrade">> => {
      return callMethod(
        OnionRouter,
        this,
        "resetUpgrade",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeDelay: async (
      params?: OnionRouterTypes.CallMethodParams<"getUpgradeDelay">
    ): Promise<OnionRouterTypes.CallMethodResult<"getUpgradeDelay">> => {
      return callMethod(
        OnionRouter,
        this,
        "getUpgradeDelay",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getOwner: async (
      params?: OnionRouterTypes.CallMethodParams<"getOwner">
    ): Promise<OnionRouterTypes.CallMethodResult<"getOwner">> => {
      return callMethod(
        OnionRouter,
        this,
        "getOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewOwner: async (
      params?: OnionRouterTypes.CallMethodParams<"getNewOwner">
    ): Promise<OnionRouterTypes.CallMethodResult<"getNewOwner">> => {
      return callMethod(
        OnionRouter,
        this,
        "getNewOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeCommenced: async (
      params?: OnionRouterTypes.CallMethodParams<"getUpgradeCommenced">
    ): Promise<OnionRouterTypes.CallMethodResult<"getUpgradeCommenced">> => {
      return callMethod(
        OnionRouter,
        this,
        "getUpgradeCommenced",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params?: OnionRouterTypes.CallMethodParams<"getNewCode">
    ): Promise<OnionRouterTypes.CallMethodResult<"getNewCode">> => {
      return callMethod(
        OnionRouter,
        this,
        "getNewCode",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    resetFields: async (
      params?: OnionRouterTypes.CallMethodParams<"resetFields">
    ): Promise<OnionRouterTypes.CallMethodResult<"resetFields">> => {
      return callMethod(
        OnionRouter,
        this,
        "resetFields",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertOnlyOwner: async (
      params: OnionRouterTypes.CallMethodParams<"assertOnlyOwner">
    ): Promise<OnionRouterTypes.CallMethodResult<"assertOnlyOwner">> => {
      return callMethod(
        OnionRouter,
        this,
        "assertOnlyOwner",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeNotPending: async (
      params?: OnionRouterTypes.CallMethodParams<"assertUpgradeNotPending">
    ): Promise<
      OnionRouterTypes.CallMethodResult<"assertUpgradeNotPending">
    > => {
      return callMethod(
        OnionRouter,
        this,
        "assertUpgradeNotPending",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params?: OnionRouterTypes.CallMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      OnionRouterTypes.CallMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return callMethod(
        OnionRouter,
        this,
        "assertUpgradeDelayElapsed",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    swapExactTokenForToken: async (
      params: OnionRouterTypes.CallMethodParams<"swapExactTokenForToken">
    ): Promise<OnionRouterTypes.CallMethodResult<"swapExactTokenForToken">> => {
      return callMethod(
        OnionRouter,
        this,
        "swapExactTokenForToken",
        params,
        getContractByCodeHash
      );
    },
    simulateSwapExactTokenForToken: async (
      params: OnionRouterTypes.CallMethodParams<"simulateSwapExactTokenForToken">
    ): Promise<
      OnionRouterTypes.CallMethodResult<"simulateSwapExactTokenForToken">
    > => {
      return callMethod(
        OnionRouter,
        this,
        "simulateSwapExactTokenForToken",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    changeOwner: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"changeOwner">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"changeOwner">> => {
      return signExecuteMethod(OnionRouter, this, "changeOwner", params);
    },
    migrate: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"migrate">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"migrate">> => {
      return signExecuteMethod(OnionRouter, this, "migrate", params);
    },
    changeOwnerApply: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"changeOwnerApply">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"changeOwnerApply">
    > => {
      return signExecuteMethod(OnionRouter, this, "changeOwnerApply", params);
    },
    migrateApply: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"migrateApply">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"migrateApply">> => {
      return signExecuteMethod(OnionRouter, this, "migrateApply", params);
    },
    migrateWithFieldsApply: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"migrateWithFieldsApply">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"migrateWithFieldsApply">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "migrateWithFieldsApply",
        params
      );
    },
    resetUpgrade: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"resetUpgrade">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"resetUpgrade">> => {
      return signExecuteMethod(OnionRouter, this, "resetUpgrade", params);
    },
    getUpgradeDelay: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"getUpgradeDelay">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"getUpgradeDelay">> => {
      return signExecuteMethod(OnionRouter, this, "getUpgradeDelay", params);
    },
    getOwner: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"getOwner">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"getOwner">> => {
      return signExecuteMethod(OnionRouter, this, "getOwner", params);
    },
    getNewOwner: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"getNewOwner">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"getNewOwner">> => {
      return signExecuteMethod(OnionRouter, this, "getNewOwner", params);
    },
    getUpgradeCommenced: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"getUpgradeCommenced">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"getUpgradeCommenced">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "getUpgradeCommenced",
        params
      );
    },
    getNewCode: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"getNewCode">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"getNewCode">> => {
      return signExecuteMethod(OnionRouter, this, "getNewCode", params);
    },
    resetFields: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"resetFields">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"resetFields">> => {
      return signExecuteMethod(OnionRouter, this, "resetFields", params);
    },
    assertOnlyOwner: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"assertOnlyOwner">
    ): Promise<OnionRouterTypes.SignExecuteMethodResult<"assertOnlyOwner">> => {
      return signExecuteMethod(OnionRouter, this, "assertOnlyOwner", params);
    },
    assertUpgradeNotPending: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"assertUpgradeNotPending">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"assertUpgradeNotPending">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "assertUpgradeNotPending",
        params
      );
    },
    assertUpgradeDelayElapsed: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "assertUpgradeDelayElapsed",
        params
      );
    },
    swapExactTokenForToken: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"swapExactTokenForToken">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"swapExactTokenForToken">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "swapExactTokenForToken",
        params
      );
    },
    simulateSwapExactTokenForToken: async (
      params: OnionRouterTypes.SignExecuteMethodParams<"simulateSwapExactTokenForToken">
    ): Promise<
      OnionRouterTypes.SignExecuteMethodResult<"simulateSwapExactTokenForToken">
    > => {
      return signExecuteMethod(
        OnionRouter,
        this,
        "simulateSwapExactTokenForToken",
        params
      );
    },
  };

  async multicall<Calls extends OnionRouterTypes.MultiCallParams>(
    calls: Calls
  ): Promise<OnionRouterTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends OnionRouterTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<OnionRouterTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | OnionRouterTypes.MultiCallParams
      | OnionRouterTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      OnionRouter,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
