
import React, { useState, useEffect } from 'react';

import { DUST_AMOUNT, prettifyTokenAmount } from '@alephium/web3'

import { Button, Card, CardBody, CardTitle, Col, Container, Row, Table } from 'react-bootstrap';
import { getContractConfig } from '../../services/utils';
import { formatAddress, formatImageSource, formatTimeAgo } from '../../components/utils/Formatters';
import './AdminPage.css'
import { FeeCollector, FeeHandler } from 'src/artifacts/ts';
import { useWallet } from '@alephium/web3-react';


const LeaderboardPage = () => {
    const { connectionStatus, nodeProvider, account, signer } = useWallet()
    
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [feeCollector, setFeeCollector] = useState<any>({});
    const [feeHandler, setFeeHandler] = useState<any>({});

    useEffect(() => {
        new Promise(async () => {
            try {
                if (loading == false) {
                    setLoading(true);
                    const feeCollectorResp = await (FeeCollector.at(getContractConfig("FeeCollector").address)).fetchState()
                    const feeHandlerResp = await (FeeHandler.at(getContractConfig("FeeHandler").address)).fetchState()
                    setFeeCollector(feeCollectorResp);
                    setFeeHandler(feeHandlerResp)
                    setLoading(false);
                }
            } catch (err) {
                setError(err as Error);
                setLoading(false);
            }
        })
    }, []);

    const collect = () => {
        if(signer){
            (FeeHandler.at(getContractConfig("FeeHandler").address)).transact.collect({
                signer: signer,
                attoAlphAmount: DUST_AMOUNT
            })
        }
    }


    const withdraw = () => {
        if(signer){
            (FeeHandler.at(getContractConfig("FeeHandler").address)).transact.withdraw({
                signer: signer,
                attoAlphAmount: DUST_AMOUNT
            })
        }
    }


    return (
        <>
            <Container fluid className="mt-5 mb-5">
                <Row>
                    <Col lg={12}>
                        <div className="pink-border mt-5">
                            <Card className="mainTable">
                                <CardBody>
                                    <pre>
                                        {JSON.stringify(feeCollector,undefined,4)}
                                    </pre>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className="pink-border mt-3">
                            <Card className="mainTable">
                                <CardBody>
                                    <pre>
                                        {JSON.stringify(feeHandler,undefined,4)}
                                    </pre>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Button onClick={collect}>Collect</Button>
                    <Button onClick={withdraw}>Withdraw</Button>
                </Row>
            </Container>
        </>
    );
};
export default LeaderboardPage;
