/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {
  Address,
  Contract,
  ContractState,
  TestContractResult,
  HexString,
  ContractFactory,
  EventSubscribeOptions,
  EventSubscription,
  CallContractParams,
  CallContractResult,
  TestContractParams,
  ContractEvent,
  subscribeContractEvent,
  subscribeContractEvents,
  testMethod,
  callMethod,
  multicallMethods,
  fetchContractState,
  Asset,
  ContractInstance,
  getContractEventsCurrentCount,
  TestContractParamsWithoutMaps,
  TestContractResultWithoutMaps,
  SignExecuteContractMethodParams,
  SignExecuteScriptTxResult,
  signExecuteMethod,
  addStdIdToFields,
  encodeContractFields,
  Narrow,
} from "@alephium/web3";
import { default as TokenLauncherContractJson } from "../launch/TokenLauncher.ral.json";
import { getContractByCodeHash } from "./contracts";
import {
  TokenLauncherSettings,
  TokenMetaData,
  UpgradableSettings,
  UserProfile,
  AllStructs,
} from "./types";
import { RalphMap } from "@alephium/web3";

// Custom types for the contract
export namespace TokenLauncherTypes {
  export type Fields = {
    settings: TokenLauncherSettings;
    upgradableSettings: UpgradableSettings;
  };

  export type State = ContractState<Fields>;

  export type ChangeOwnerCommenceEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type ChangeOwnerApplyEvent = ContractEvent<{
    owner: Address;
    changeOwner: Address;
  }>;
  export type MigrateCommenceEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
  }>;
  export type MigrateWithFieldsApplyEvent = ContractEvent<{
    owner: Address;
    changeCode: HexString;
    changeImmFieldsEncoded: HexString;
    changeMutFieldsEncoded: HexString;
  }>;
  export type CreateTokenEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    symbol: HexString;
    name: HexString;
    decimals: bigint;
    totalSupply: bigint;
    royaltyFeeCollector: HexString;
  }>;
  export type CreateBondingCurveEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    bpContract: HexString;
  }>;
  export type UpdateTokenMetaEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    logo: HexString;
    description: HexString;
    socials: HexString;
  }>;
  export type UpdateTokenBondingCurveEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    bondingCurve: HexString;
  }>;
  export type UpdateTokenDexPairEvent = ContractEvent<{
    tokenId: HexString;
    caller: Address;
    dexPair: HexString;
  }>;

  export interface CallMethodTable {
    changeOwner: {
      params: CallContractParams<{ changeOwner: Address }>;
      result: CallContractResult<null>;
    };
    migrate: {
      params: CallContractParams<{ changeCode: HexString }>;
      result: CallContractResult<null>;
    };
    changeOwnerApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateApply: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    migrateWithFieldsApply: {
      params: CallContractParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: CallContractResult<null>;
    };
    resetUpgrade: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    getUpgradeDelay: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getNewOwner: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<Address>;
    };
    getUpgradeCommenced: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<bigint>;
    };
    getNewCode: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<HexString>;
    };
    resetFields: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertOnlyOwner: {
      params: CallContractParams<{ caller: Address }>;
      result: CallContractResult<null>;
    };
    assertUpgradeNotPending: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<CallContractParams<{}>, "args">;
      result: CallContractResult<null>;
    };
    createToken: {
      params: CallContractParams<{
        symbol: HexString;
        name: HexString;
        logo: HexString;
        description: HexString;
        socials: HexString;
      }>;
      result: CallContractResult<HexString>;
    };
    createBondingCurve: {
      params: CallContractParams<{ tokenId: HexString }>;
      result: CallContractResult<HexString>;
    };
    createDexPair: {
      params: CallContractParams<{ tokenId: HexString }>;
      result: CallContractResult<HexString>;
    };
    updateTokenMeta: {
      params: CallContractParams<{
        tokenId: HexString;
        logo: HexString;
        description: HexString;
        socials: HexString;
      }>;
      result: CallContractResult<null>;
    };
    loadTokenMeta: {
      params: CallContractParams<{ tokenId: HexString }>;
      result: CallContractResult<TokenMetaData>;
    };
    updateSettings: {
      params: CallContractParams<{ newSettings: TokenLauncherSettings }>;
      result: CallContractResult<null>;
    };
  }
  export type CallMethodParams<T extends keyof CallMethodTable> =
    CallMethodTable[T]["params"];
  export type CallMethodResult<T extends keyof CallMethodTable> =
    CallMethodTable[T]["result"];
  export type MultiCallParams = Partial<{
    [Name in keyof CallMethodTable]: CallMethodTable[Name]["params"];
  }>;
  export type MultiCallResults<T extends MultiCallParams> = {
    [MaybeName in keyof T]: MaybeName extends keyof CallMethodTable
      ? CallMethodTable[MaybeName]["result"]
      : undefined;
  };
  export type MulticallReturnType<Callss extends MultiCallParams[]> = {
    [index in keyof Callss]: MultiCallResults<Callss[index]>;
  };

  export interface SignExecuteMethodTable {
    changeOwner: {
      params: SignExecuteContractMethodParams<{ changeOwner: Address }>;
      result: SignExecuteScriptTxResult;
    };
    migrate: {
      params: SignExecuteContractMethodParams<{ changeCode: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    changeOwnerApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateApply: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    migrateWithFieldsApply: {
      params: SignExecuteContractMethodParams<{
        newImmFieldsEncoded: HexString;
        newMutFieldsEncoded: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    resetUpgrade: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeDelay: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewOwner: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getUpgradeCommenced: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    getNewCode: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    resetFields: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertOnlyOwner: {
      params: SignExecuteContractMethodParams<{ caller: Address }>;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeNotPending: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    assertUpgradeDelayElapsed: {
      params: Omit<SignExecuteContractMethodParams<{}>, "args">;
      result: SignExecuteScriptTxResult;
    };
    createToken: {
      params: SignExecuteContractMethodParams<{
        symbol: HexString;
        name: HexString;
        logo: HexString;
        description: HexString;
        socials: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    createBondingCurve: {
      params: SignExecuteContractMethodParams<{ tokenId: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    createDexPair: {
      params: SignExecuteContractMethodParams<{ tokenId: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    updateTokenMeta: {
      params: SignExecuteContractMethodParams<{
        tokenId: HexString;
        logo: HexString;
        description: HexString;
        socials: HexString;
      }>;
      result: SignExecuteScriptTxResult;
    };
    loadTokenMeta: {
      params: SignExecuteContractMethodParams<{ tokenId: HexString }>;
      result: SignExecuteScriptTxResult;
    };
    updateSettings: {
      params: SignExecuteContractMethodParams<{
        newSettings: TokenLauncherSettings;
      }>;
      result: SignExecuteScriptTxResult;
    };
  }
  export type SignExecuteMethodParams<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["params"];
  export type SignExecuteMethodResult<T extends keyof SignExecuteMethodTable> =
    SignExecuteMethodTable[T]["result"];

  export type Maps = { tokenMetaCollection?: Map<HexString, TokenMetaData> };
}

class Factory extends ContractFactory<
  TokenLauncherInstance,
  TokenLauncherTypes.Fields
> {
  encodeFields(fields: TokenLauncherTypes.Fields) {
    return encodeContractFields(
      addStdIdToFields(this.contract, fields),
      this.contract.fieldsSig,
      AllStructs
    );
  }

  eventIndex = {
    ChangeOwnerCommence: 0,
    ChangeOwnerApply: 1,
    MigrateCommence: 2,
    MigrateApply: 3,
    MigrateWithFieldsApply: 4,
    CreateToken: 5,
    CreateBondingCurve: 6,
    UpdateTokenMeta: 7,
    UpdateTokenBondingCurve: 8,
    UpdateTokenDexPair: 9,
  };
  consts = {
    MEMEDECIMALS: BigInt("18"),
    MEMEAMOUNT: BigInt("1000000000000000000000000000"),
    MEMELAUNCHAMOUNT: BigInt("800000000000000000000000000"),
    K: BigInt("1000000000000"),
    A: BigInt("3745000000"),
    UpgradeErrorCodes: {
      Forbidden: BigInt("13000"),
      UpgradePending: BigInt("13001"),
      UpgradeNotPending: BigInt("13002"),
      UpgradeDelayNotExpired: BigInt("13003"),
      MigrateNotPending: BigInt("13004"),
      MigrateWithFieldsNotPending: BigInt("13005"),
      ChangeOwnerNotPending: BigInt("13006"),
    },
    ErrorCodes: {
      Unauthorized: BigInt("1"),
      TokenDoesntExist: BigInt("2"),
      BondingPairAlreadyCreated: BigInt("3"),
      DexPairAlreadyCreated: BigInt("4"),
      NameTooLong: BigInt("5"),
      SymbolTooLong: BigInt("6"),
      LogoTooLong: BigInt("7"),
      DescriptionTooLong: BigInt("8"),
      SocialsTooLong: BigInt("9"),
    },
  };

  at(address: string): TokenLauncherInstance {
    return new TokenLauncherInstance(address);
  }

  tests = {
    changeOwner: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { changeOwner: Address },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "changeOwner", params, getContractByCodeHash);
    },
    migrate: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { changeCode: HexString },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "migrate", params, getContractByCodeHash);
    },
    changeOwnerApply: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "changeOwnerApply",
        params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "migrateApply", params, getContractByCodeHash);
    },
    migrateWithFieldsApply: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { newImmFieldsEncoded: HexString; newMutFieldsEncoded: HexString },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "resetUpgrade", params, getContractByCodeHash);
    },
    getUpgradeDelay: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<bigint, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "getUpgradeDelay", params, getContractByCodeHash);
    },
    getOwner: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<Address, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "getOwner", params, getContractByCodeHash);
    },
    getNewOwner: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<Address, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "getNewOwner", params, getContractByCodeHash);
    },
    getUpgradeCommenced: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<bigint, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "getUpgradeCommenced",
        params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<HexString, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "getNewCode", params, getContractByCodeHash);
    },
    resetFields: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "resetFields", params, getContractByCodeHash);
    },
    assertOnlyOwner: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { caller: Address },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "assertOnlyOwner", params, getContractByCodeHash);
    },
    assertUpgradeNotPending: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "assertUpgradeNotPending",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params: Omit<
        TestContractParams<
          TokenLauncherTypes.Fields,
          never,
          TokenLauncherTypes.Maps
        >,
        "testArgs"
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "assertUpgradeDelayElapsed",
        params,
        getContractByCodeHash
      );
    },
    createToken: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        {
          symbol: HexString;
          name: HexString;
          logo: HexString;
          description: HexString;
          socials: HexString;
        },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<HexString, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "createToken", params, getContractByCodeHash);
    },
    createBondingCurve: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { tokenId: HexString },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<HexString, TokenLauncherTypes.Maps>> => {
      return testMethod(
        this,
        "createBondingCurve",
        params,
        getContractByCodeHash
      );
    },
    createDexPair: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { tokenId: HexString },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<HexString, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "createDexPair", params, getContractByCodeHash);
    },
    updateTokenMeta: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        {
          tokenId: HexString;
          logo: HexString;
          description: HexString;
          socials: HexString;
        },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "updateTokenMeta", params, getContractByCodeHash);
    },
    loadTokenMeta: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { tokenId: HexString },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<TokenMetaData, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "loadTokenMeta", params, getContractByCodeHash);
    },
    updateSettings: async (
      params: TestContractParams<
        TokenLauncherTypes.Fields,
        { newSettings: TokenLauncherSettings },
        TokenLauncherTypes.Maps
      >
    ): Promise<TestContractResult<null, TokenLauncherTypes.Maps>> => {
      return testMethod(this, "updateSettings", params, getContractByCodeHash);
    },
  };

  stateForTest(
    initFields: TokenLauncherTypes.Fields,
    asset?: Asset,
    address?: string,
    maps?: TokenLauncherTypes.Maps
  ) {
    return this.stateForTest_(initFields, asset, address, maps);
  }
}

// Use this object to test and deploy the contract
export const TokenLauncher = new Factory(
  Contract.fromJson(
    TokenLauncherContractJson,
    "=66-2+85=2-1=1-2=1-1=2-2=1-1+7=1-1+4f2=2-1+66=1+58d=697-1+d=466+7a7e0214696e73657274206174206d617020706174683a2000=1628",
    "6f3b2ad9044885afa9d2cce9a5da29beddee7edbd3bc25bd0d5f0ee89dae3de2",
    AllStructs
  )
);

// Use this class to interact with the blockchain
export class TokenLauncherInstance extends ContractInstance {
  constructor(address: Address) {
    super(address);
  }

  maps = {
    tokenMetaCollection: new RalphMap<HexString, TokenMetaData>(
      TokenLauncher.contract,
      this.contractId,
      "tokenMetaCollection"
    ),
  };

  async fetchState(): Promise<TokenLauncherTypes.State> {
    return fetchContractState(TokenLauncher, this);
  }

  async getContractEventsCurrentCount(): Promise<number> {
    return getContractEventsCurrentCount(this.address);
  }

  subscribeChangeOwnerCommenceEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.ChangeOwnerCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "ChangeOwnerCommence",
      fromCount
    );
  }

  subscribeChangeOwnerApplyEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.ChangeOwnerApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "ChangeOwnerApply",
      fromCount
    );
  }

  subscribeMigrateCommenceEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.MigrateCommenceEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "MigrateCommence",
      fromCount
    );
  }

  subscribeMigrateApplyEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.MigrateApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "MigrateApply",
      fromCount
    );
  }

  subscribeMigrateWithFieldsApplyEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.MigrateWithFieldsApplyEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "MigrateWithFieldsApply",
      fromCount
    );
  }

  subscribeCreateTokenEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.CreateTokenEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "CreateToken",
      fromCount
    );
  }

  subscribeCreateBondingCurveEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.CreateBondingCurveEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "CreateBondingCurve",
      fromCount
    );
  }

  subscribeUpdateTokenMetaEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.UpdateTokenMetaEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "UpdateTokenMeta",
      fromCount
    );
  }

  subscribeUpdateTokenBondingCurveEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.UpdateTokenBondingCurveEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "UpdateTokenBondingCurve",
      fromCount
    );
  }

  subscribeUpdateTokenDexPairEvent(
    options: EventSubscribeOptions<TokenLauncherTypes.UpdateTokenDexPairEvent>,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvent(
      TokenLauncher.contract,
      this,
      options,
      "UpdateTokenDexPair",
      fromCount
    );
  }

  subscribeAllEvents(
    options: EventSubscribeOptions<
      | TokenLauncherTypes.ChangeOwnerCommenceEvent
      | TokenLauncherTypes.ChangeOwnerApplyEvent
      | TokenLauncherTypes.MigrateCommenceEvent
      | TokenLauncherTypes.MigrateApplyEvent
      | TokenLauncherTypes.MigrateWithFieldsApplyEvent
      | TokenLauncherTypes.CreateTokenEvent
      | TokenLauncherTypes.CreateBondingCurveEvent
      | TokenLauncherTypes.UpdateTokenMetaEvent
      | TokenLauncherTypes.UpdateTokenBondingCurveEvent
      | TokenLauncherTypes.UpdateTokenDexPairEvent
    >,
    fromCount?: number
  ): EventSubscription {
    return subscribeContractEvents(
      TokenLauncher.contract,
      this,
      options,
      fromCount
    );
  }

  view = {
    changeOwner: async (
      params: TokenLauncherTypes.CallMethodParams<"changeOwner">
    ): Promise<TokenLauncherTypes.CallMethodResult<"changeOwner">> => {
      return callMethod(
        TokenLauncher,
        this,
        "changeOwner",
        params,
        getContractByCodeHash
      );
    },
    migrate: async (
      params: TokenLauncherTypes.CallMethodParams<"migrate">
    ): Promise<TokenLauncherTypes.CallMethodResult<"migrate">> => {
      return callMethod(
        TokenLauncher,
        this,
        "migrate",
        params,
        getContractByCodeHash
      );
    },
    changeOwnerApply: async (
      params?: TokenLauncherTypes.CallMethodParams<"changeOwnerApply">
    ): Promise<TokenLauncherTypes.CallMethodResult<"changeOwnerApply">> => {
      return callMethod(
        TokenLauncher,
        this,
        "changeOwnerApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateApply: async (
      params?: TokenLauncherTypes.CallMethodParams<"migrateApply">
    ): Promise<TokenLauncherTypes.CallMethodResult<"migrateApply">> => {
      return callMethod(
        TokenLauncher,
        this,
        "migrateApply",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    migrateWithFieldsApply: async (
      params: TokenLauncherTypes.CallMethodParams<"migrateWithFieldsApply">
    ): Promise<
      TokenLauncherTypes.CallMethodResult<"migrateWithFieldsApply">
    > => {
      return callMethod(
        TokenLauncher,
        this,
        "migrateWithFieldsApply",
        params,
        getContractByCodeHash
      );
    },
    resetUpgrade: async (
      params?: TokenLauncherTypes.CallMethodParams<"resetUpgrade">
    ): Promise<TokenLauncherTypes.CallMethodResult<"resetUpgrade">> => {
      return callMethod(
        TokenLauncher,
        this,
        "resetUpgrade",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeDelay: async (
      params?: TokenLauncherTypes.CallMethodParams<"getUpgradeDelay">
    ): Promise<TokenLauncherTypes.CallMethodResult<"getUpgradeDelay">> => {
      return callMethod(
        TokenLauncher,
        this,
        "getUpgradeDelay",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getOwner: async (
      params?: TokenLauncherTypes.CallMethodParams<"getOwner">
    ): Promise<TokenLauncherTypes.CallMethodResult<"getOwner">> => {
      return callMethod(
        TokenLauncher,
        this,
        "getOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewOwner: async (
      params?: TokenLauncherTypes.CallMethodParams<"getNewOwner">
    ): Promise<TokenLauncherTypes.CallMethodResult<"getNewOwner">> => {
      return callMethod(
        TokenLauncher,
        this,
        "getNewOwner",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getUpgradeCommenced: async (
      params?: TokenLauncherTypes.CallMethodParams<"getUpgradeCommenced">
    ): Promise<TokenLauncherTypes.CallMethodResult<"getUpgradeCommenced">> => {
      return callMethod(
        TokenLauncher,
        this,
        "getUpgradeCommenced",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    getNewCode: async (
      params?: TokenLauncherTypes.CallMethodParams<"getNewCode">
    ): Promise<TokenLauncherTypes.CallMethodResult<"getNewCode">> => {
      return callMethod(
        TokenLauncher,
        this,
        "getNewCode",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    resetFields: async (
      params?: TokenLauncherTypes.CallMethodParams<"resetFields">
    ): Promise<TokenLauncherTypes.CallMethodResult<"resetFields">> => {
      return callMethod(
        TokenLauncher,
        this,
        "resetFields",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertOnlyOwner: async (
      params: TokenLauncherTypes.CallMethodParams<"assertOnlyOwner">
    ): Promise<TokenLauncherTypes.CallMethodResult<"assertOnlyOwner">> => {
      return callMethod(
        TokenLauncher,
        this,
        "assertOnlyOwner",
        params,
        getContractByCodeHash
      );
    },
    assertUpgradeNotPending: async (
      params?: TokenLauncherTypes.CallMethodParams<"assertUpgradeNotPending">
    ): Promise<
      TokenLauncherTypes.CallMethodResult<"assertUpgradeNotPending">
    > => {
      return callMethod(
        TokenLauncher,
        this,
        "assertUpgradeNotPending",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    assertUpgradeDelayElapsed: async (
      params?: TokenLauncherTypes.CallMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      TokenLauncherTypes.CallMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return callMethod(
        TokenLauncher,
        this,
        "assertUpgradeDelayElapsed",
        params === undefined ? {} : params,
        getContractByCodeHash
      );
    },
    createToken: async (
      params: TokenLauncherTypes.CallMethodParams<"createToken">
    ): Promise<TokenLauncherTypes.CallMethodResult<"createToken">> => {
      return callMethod(
        TokenLauncher,
        this,
        "createToken",
        params,
        getContractByCodeHash
      );
    },
    createBondingCurve: async (
      params: TokenLauncherTypes.CallMethodParams<"createBondingCurve">
    ): Promise<TokenLauncherTypes.CallMethodResult<"createBondingCurve">> => {
      return callMethod(
        TokenLauncher,
        this,
        "createBondingCurve",
        params,
        getContractByCodeHash
      );
    },
    createDexPair: async (
      params: TokenLauncherTypes.CallMethodParams<"createDexPair">
    ): Promise<TokenLauncherTypes.CallMethodResult<"createDexPair">> => {
      return callMethod(
        TokenLauncher,
        this,
        "createDexPair",
        params,
        getContractByCodeHash
      );
    },
    updateTokenMeta: async (
      params: TokenLauncherTypes.CallMethodParams<"updateTokenMeta">
    ): Promise<TokenLauncherTypes.CallMethodResult<"updateTokenMeta">> => {
      return callMethod(
        TokenLauncher,
        this,
        "updateTokenMeta",
        params,
        getContractByCodeHash
      );
    },
    loadTokenMeta: async (
      params: TokenLauncherTypes.CallMethodParams<"loadTokenMeta">
    ): Promise<TokenLauncherTypes.CallMethodResult<"loadTokenMeta">> => {
      return callMethod(
        TokenLauncher,
        this,
        "loadTokenMeta",
        params,
        getContractByCodeHash
      );
    },
    updateSettings: async (
      params: TokenLauncherTypes.CallMethodParams<"updateSettings">
    ): Promise<TokenLauncherTypes.CallMethodResult<"updateSettings">> => {
      return callMethod(
        TokenLauncher,
        this,
        "updateSettings",
        params,
        getContractByCodeHash
      );
    },
  };

  transact = {
    changeOwner: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"changeOwner">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"changeOwner">> => {
      return signExecuteMethod(TokenLauncher, this, "changeOwner", params);
    },
    migrate: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"migrate">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"migrate">> => {
      return signExecuteMethod(TokenLauncher, this, "migrate", params);
    },
    changeOwnerApply: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"changeOwnerApply">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"changeOwnerApply">
    > => {
      return signExecuteMethod(TokenLauncher, this, "changeOwnerApply", params);
    },
    migrateApply: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"migrateApply">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"migrateApply">> => {
      return signExecuteMethod(TokenLauncher, this, "migrateApply", params);
    },
    migrateWithFieldsApply: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"migrateWithFieldsApply">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"migrateWithFieldsApply">
    > => {
      return signExecuteMethod(
        TokenLauncher,
        this,
        "migrateWithFieldsApply",
        params
      );
    },
    resetUpgrade: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"resetUpgrade">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"resetUpgrade">> => {
      return signExecuteMethod(TokenLauncher, this, "resetUpgrade", params);
    },
    getUpgradeDelay: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"getUpgradeDelay">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"getUpgradeDelay">
    > => {
      return signExecuteMethod(TokenLauncher, this, "getUpgradeDelay", params);
    },
    getOwner: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"getOwner">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"getOwner">> => {
      return signExecuteMethod(TokenLauncher, this, "getOwner", params);
    },
    getNewOwner: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"getNewOwner">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"getNewOwner">> => {
      return signExecuteMethod(TokenLauncher, this, "getNewOwner", params);
    },
    getUpgradeCommenced: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"getUpgradeCommenced">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"getUpgradeCommenced">
    > => {
      return signExecuteMethod(
        TokenLauncher,
        this,
        "getUpgradeCommenced",
        params
      );
    },
    getNewCode: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"getNewCode">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"getNewCode">> => {
      return signExecuteMethod(TokenLauncher, this, "getNewCode", params);
    },
    resetFields: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"resetFields">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"resetFields">> => {
      return signExecuteMethod(TokenLauncher, this, "resetFields", params);
    },
    assertOnlyOwner: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"assertOnlyOwner">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"assertOnlyOwner">
    > => {
      return signExecuteMethod(TokenLauncher, this, "assertOnlyOwner", params);
    },
    assertUpgradeNotPending: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"assertUpgradeNotPending">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"assertUpgradeNotPending">
    > => {
      return signExecuteMethod(
        TokenLauncher,
        this,
        "assertUpgradeNotPending",
        params
      );
    },
    assertUpgradeDelayElapsed: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"assertUpgradeDelayElapsed">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"assertUpgradeDelayElapsed">
    > => {
      return signExecuteMethod(
        TokenLauncher,
        this,
        "assertUpgradeDelayElapsed",
        params
      );
    },
    createToken: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"createToken">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"createToken">> => {
      return signExecuteMethod(TokenLauncher, this, "createToken", params);
    },
    createBondingCurve: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"createBondingCurve">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"createBondingCurve">
    > => {
      return signExecuteMethod(
        TokenLauncher,
        this,
        "createBondingCurve",
        params
      );
    },
    createDexPair: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"createDexPair">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"createDexPair">> => {
      return signExecuteMethod(TokenLauncher, this, "createDexPair", params);
    },
    updateTokenMeta: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"updateTokenMeta">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"updateTokenMeta">
    > => {
      return signExecuteMethod(TokenLauncher, this, "updateTokenMeta", params);
    },
    loadTokenMeta: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"loadTokenMeta">
    ): Promise<TokenLauncherTypes.SignExecuteMethodResult<"loadTokenMeta">> => {
      return signExecuteMethod(TokenLauncher, this, "loadTokenMeta", params);
    },
    updateSettings: async (
      params: TokenLauncherTypes.SignExecuteMethodParams<"updateSettings">
    ): Promise<
      TokenLauncherTypes.SignExecuteMethodResult<"updateSettings">
    > => {
      return signExecuteMethod(TokenLauncher, this, "updateSettings", params);
    },
  };

  async multicall<Calls extends TokenLauncherTypes.MultiCallParams>(
    calls: Calls
  ): Promise<TokenLauncherTypes.MultiCallResults<Calls>>;
  async multicall<Callss extends TokenLauncherTypes.MultiCallParams[]>(
    callss: Narrow<Callss>
  ): Promise<TokenLauncherTypes.MulticallReturnType<Callss>>;
  async multicall<
    Callss extends
      | TokenLauncherTypes.MultiCallParams
      | TokenLauncherTypes.MultiCallParams[]
  >(callss: Callss): Promise<unknown> {
    return await multicallMethods(
      TokenLauncher,
      this,
      callss,
      getContractByCodeHash
    );
  }
}
