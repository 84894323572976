import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { copyText, formatCAWithRef } from '../utils/Formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const CopyButtonWithTooltip: React.FC<{ tokenId: string, account: any }> = ({ tokenId, account }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleCopyClick = () => {
        copyText(formatCAWithRef(tokenId, account));
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
    };

    return (
        <OverlayTrigger
            show={showTooltip}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-top`}>
                    Copied to clipboard!
                </Tooltip>
            }
        >
            {({ ref, ...triggerHandler }) => (
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleCopyClick}
                    ref={ref}
                    {...triggerHandler}
                >
                    <FontAwesomeIcon icon={faCopy} /> ca: {tokenId.substring(0, 4)}...</Button>
            )}
        </OverlayTrigger>
    );
};

export default CopyButtonWithTooltip